.faq-list {
  width: 100%;
  /* border-top: 1px solid #DDD; */
  /* border-bottom: 1px solid #DDD; */
}

.faq-item {
  padding: 36px 0;
  border-bottom: 1px solid #313535;
}

.faq-item:last-child {
  border: 0;
}

.faq-question {
  font-size: 24px;
  font-weight: 600;
  line-height: 28px;
  letter-spacing: -0.005em;
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
}

.faq-question svg {
  min-width: 24px;
}

.faq-answer {
  max-height: 0;
  margin: 0;
  color: #979797;
  font-size: 20px;
  font-weight: 400;
  line-height: 36px;
  letter-spacing: -0.01em;
  transition: 0.3s;
  overflow: hidden;
  opacity: 0;
}

.faq-answer.open {
  padding-top: 15px;
  max-height: 1000px;
  /* Set a max-height high enough to show any content */
  opacity: 1;
}

.faq-icon {
  transform: rotate(0deg);
  transition: transform 0.3s ease;
}

.faq-icon.open {
  transform: rotate(45deg);
}

.faq-icon {
  width: 24px;
  height: 24px;
  margin-left: 0.5rem;
  transition: transform 0.2s ease-in-out;
}

.faq-icon.open {
  transform: rotate(180deg);
}

/* You might need to adjust the styles based on your actual HTML structure and page styles */
@media(max-width : 767px) {
  .faq-question {
    font-size: 24px;
    font-weight: 600;
    line-height: 36px;
    letter-spacing: -0.005em;
  }

  .faq-answer {
    font-size: 15px;
    font-weight: 400;
    line-height: 28px;
    letter-spacing: -0.01em;
  }

  .about-us-page .banner-button {
    width: 100%;
    margin-top: 25px;
  }
}