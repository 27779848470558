.navbar-navbar {
  position: sticky;
  top: 0;
  z-index: 9999;
  width: 100%;
}

.navbar-navbar .navbar-content {
  display: flex;
  align-items: flex-start;
  flex-direction: row;
  background-color: #fff;
  justify-content: space-between;
  padding: 20px 0;
  transition: padding 0.3s;
}

.navbar-offers {
  color: #fff;
  background-color: #101938;
  padding: 15px 0;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
}

.navbar-navbar.sticky .navbar-content {
  padding: 10px 0;
}

.navbar-desktop {
  flex: 1;
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: space-between;
}

.navbar-main {
  gap: var(--dl-space-space-fiveunits);
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: center;
}

.navbar-branding {
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: center;
}

.navbar-navlink {
  display: contents;
}

.navbar-finbest {
  width: 105px;
  text-decoration: none;
}

.navbar-links {
  gap: var(--dl-space-space-threeunits);
  display: flex;
  align-items: flex-start;
  padding-top: 12px;
  border-radius: 6px;
  flex-direction: row;
  padding-bottom: 12px;
  justify-content: center;
}

.navbar-links .link {
  color: #686868;
  transition: 0.3s;
}

.navbar-links .link:hover {
  color: #333;
}

.navbar-links .link.active, .navbar-navlink2.dropdown-item.active {
  color: #333;
  background: #fff;
}

.navbar-navlink2.dropdown-item {
  padding: 8px 15px;
}

.navbar-quick-actions {
  gap: var(--dl-space-space-oneandhalfunits);
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: center;
}

.navbar-quick-actions button {
  min-width: 144px;
}

.navbar-navlink1 {
  display: contents;
}

.navbar-sign-up {
  font-style: normal;
  font-weight: 500;
}

.navbar-hamburger-menu {
  display: none;
}

.navbar-mobile {
  background: #fff;
  gap: var(--dl-space-space-fourunits);
  top: 0px;
  left: 0px;
  width: 100vw;
  height: 100vh;
  display: flex;
  padding: 25px 50px;
  z-index: 200;
  position: fixed;
  transform: translateX(100%);
  transition: 0.3s;
  align-items: flex-start;
  flex-direction: column;
  /* background-color: #ffffff; */
}

.navbar-top {
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: space-between;
}

.navbar-image {
  width: 150px;
  object-fit: cover;
}

.navbar-icon {
  width: 28px;
  height: 28px;
}

.navbar-links1 {
  gap: 16px;
  width: 100%;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: center;
}

.navbar-navlink2 {
  width: 100%;
  padding: 4px 0;
  color: #686868;
  font-size: 15px;
  text-decoration: none;
  transition: 0.3s;
}

.navbar-navlink3 {
  text-decoration: none;
}

.navbar-navlink4 {
  text-decoration: none;
}

.navbar-navlink5 {
  text-decoration: none;
}

.navbar-buttons {
  gap: 15px;
  width: 100%;
  display: flex;
  align-items: flex-start;
  padding-top: var(--dl-space-space-unit);
  flex-direction: column;
  padding-bottom: var(--dl-space-space-unit);
  justify-content: center;
}

.navbar-navlink6 {
  display: contents;
}

.navbar-btn {
  width: 100%;
  display: flex;
  padding: var(--dl-space-space-unit);
  align-items: center;
  border-radius: var(--dl-radius-radius-radius8);
  flex-direction: column;
  justify-content: center;
  text-decoration: none;
  background-color: #0be096;
}

.navbar-text {
  font-style: normal;
  font-weight: 500;
}

.navbar-navlink7 {
  display: contents;
}

.navbar-btn1 {
  width: 100%;
  display: flex;
  padding: var(--dl-space-space-unit);
  align-items: center;
  border-radius: var(--dl-radius-radius-radius8);
  flex-direction: column;
  justify-content: center;
  text-decoration: none;
  background-color: #0be096;
}

.navbar-text1 {
  font-style: normal;
  font-weight: 500;
}

.navbar-container1 {
  display: contents;
}

@media(max-width: 991px) {
  .navbar-offers {
    text-align: center;
    flex-direction: column;
  }

  .navbar-navbar .navbar-content {
    align-items: center;
    padding-left: var(--dl-space-space-oneandhalfunits);
    padding-right: var(--dl-space-space-oneandhalfunits);
  }

  .navbar-links {
    display: none;
  }

  .navbar-quick-actions button {
    display: none;
  }

  .navbar-hamburger-menu {
    display: flex;
  }
}

@media(max-width: 767px) {
  .navbar-navbar .navbar-content {
    align-items: center;
  }
}