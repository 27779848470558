.quote-quote {
  gap: var(--dl-space-space-oneandhalfunits);
  display: flex;
  position: relative;
  align-items: center;
  flex-direction: column;
}
.quote-quote1 {
  display: flex;
  align-items: center;
  text-overflow: ellipsis;
  flex-direction: column;
}
.quote-quote2 {
  font-size: 18px;
  font-style: italic;
  text-align: center;
  font-family: Poppins;
  font-weight: 400;
  line-height: 28px;
}
.quote-people {
  gap: var(--dl-space-space-sixunits);
  cursor: pointer;
  display: flex;
  align-items: center;
  flex-direction: row;
}
.quote-person {
  gap: var(--dl-space-space-unit);
  display: flex;
  align-items: center;
  flex-direction: row;
}
.quote-avatar {
  width: 55px;
  height: 55px;
  border-radius: var(--dl-radius-radius-round);
  object-fit: cover;
}
.quote-person-details {
  gap: var(--dl-space-space-halfunit);
  display: flex;
  align-items: center;
  flex-direction: column;
}
.quote-text {
  font-style: normal;
  font-weight: 600;
}



@media(max-width: 767px) {
  .quote-quote2 {
    font-size: 18px;
    margin-top: 16px;
    line-height: 28px;
  }
  .quote-person {
    flex-direction: column;
  }
  .quote-person-details {
    align-items: center;
  }
}
