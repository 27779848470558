:root {
  --dl-color-gray-500: #595959;
  --dl-color-gray-700: #999999;
  --dl-color-gray-900: #D9D9D9;
  --dl-size-size-large: 144px;
  --dl-size-size-small: 48px;
  --dl-color-danger-300: #A22020;
  --dl-color-danger-500: #BF2626;
  --dl-color-danger-700: #E14747;
  --dl-color-gray-black: #000000;
  --dl-color-gray-white: #FFFFFF;
  --dl-size-size-medium: 96px;
  --dl-size-size-xlarge: 192px;
  --dl-size-size-xsmall: 16px;
  --dl-space-space-unit: 16px;
  /* --dl-color-primary-100: #003EB3; */
  --dl-color-correct: #1CEC79;
  /* --dl-color-primary-100: #214889; */
  --dl-color-primary-100: #0be096;


  --dl-color-primary-300: #0074F0;
  --dl-color-primary-500: #14A9FF;
  --dl-color-primary-700: #85DCFF;
  --dl-color-success-300: #199033;
  --dl-color-success-500: #32A94C;
  --dl-color-success-700: #4CC366;
  --dl-size-size-xxlarge: 288px;
  --dl-size-size-maxwidth: 1400px;
  --dl-radius-radius-round: 50%;
  --dl-space-space-halfunit: 8px;
  --dl-space-space-sixunits: 96px;
  --dl-space-space-twounits: 32px;
  --dl-radius-radius-radius2: 2px;
  --dl-radius-radius-radius4: 4px;
  --dl-radius-radius-radius8: 8px;
  --dl-space-space-fiveunits: 80px;
  --dl-space-space-fourunits: 64px;
  --dl-space-space-threeunits: 48px;
  --dl-space-space-oneandhalfunits: 15px;
}

body {
  font-family: Inter;
}

.button {
  color: var(--dl-color-gray-black);
  display: inline-block;
  padding: 0.5rem 1rem;
  border-color: var(--dl-color-gray-black);
  border-width: 1px;
  border-radius: 4px;
  background-color: var(--dl-color-gray-white);
}

.input {
  color: var(--dl-color-gray-black);
  cursor: auto;
  padding: 0.5rem 1rem;
  border-color: var(--dl-color-gray-black);
  border-width: 1px;
  border-radius: 4px;
  background-color: var(--dl-color-gray-white);
}

.textarea {
  color: var(--dl-color-gray-black);
  cursor: auto;
  padding: 0.5rem;
  border-color: var(--dl-color-gray-black);
  border-width: 1px;
  border-radius: 4px;
  background-color: var(--dl-color-gray-white);
}

.list {
  width: 100%;
  margin: 1em 0px 1em 0px;
  display: block;
  padding: 0px 0px 0px 1.5rem;
  list-style-type: none;
  list-style-position: outside;
}

.list-item {
  display: list-item;
}

.teleport-show {
  display: flex !important;
  transform: none !important;
}

.link {
  font-style: normal;
  transition: 0.3s;
  font-weight: 500;
  line-height: 24px;
  text-decoration: none;
}

.link:hover {
  /* color: rgba(0, 0, 0, 0.6); */
}

.accordion-element {
  gap: var(--dl-space-space-fiveunits);
  cursor: pointer;
  display: flex;
  position: relative;
  transition: .5s all linear;
  ;
  align-items: flex-start;
  padding-top: var(--dl-space-space-oneandhalfunits);
  border-color: #DBDBDB;
  flex-direction: row;
  padding-bottom: var(--dl-space-space-oneandhalfunits);
  justify-content: center;
  border-bottom-width: 1px;
}

.accordion-element:hover {
  fill: rgba(0, 0, 0, 0.5);
  color: rgba(0, 0, 0, 0.5);
}

.Question {
  gap: var(--dl-space-space-unit);
  width: 100%;
  display: flex;
  padding: var(--dl-space-space-fiveunits);
  max-width: 640px;
  align-items: flex-start;
  border-color: #DBDBDB;
  flex-direction: column;
  border-right-width: 1px;
  border-bottom-width: 1px;
}

.active-quote {
  display: flex !important;
  align-items: center;
  flex-direction: column;
}

.quote {
  display: none;
}

.Content {
  font-size: 16px;
  font-family: Inter;
  font-weight: 400;
  line-height: 1.15;
  text-transform: none;
  text-decoration: none;
}

.Heading {
  font-size: 32px;
  font-family: Inter;
  font-weight: 700;
  line-height: 1.15;
  text-transform: none;
  text-decoration: none;
}

@media(max-width: 767px) {
  .Question {
    gap: var(--dl-space-space-halfunit);
    padding-top: var(--dl-space-space-oneandhalfunits);
    padding-left: 0px;
    padding-right: 0px;
    padding-bottom: var(--dl-space-space-oneandhalfunits);
    border-right-width: 0px;
  }
}

/* You can customize this to center your content on the video background */
.content-container {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 1;
  color: white;
}

.p-t-0 {
  padding-top: 0px !important;
}

.gradient-text {
  -webkit-text-fill-color: #0000;
  -moz-text-fill-color: #0000;
  background-clip: text;
  -webkit-background-clip: text;
  -moz-background-clip: text;
  background-image: linear-gradient(270deg, #1F86DB 0%, #0BE096 100%);
}

.btn {
  --bs-btn-padding-x: 16px;
  --bs-btn-padding-y: 10px;
}

.btn-primary {
  --bs-btn-bg: #3477f1;
  --bs-btn-border-color: #3477f1;
  --bs-btn-hover-bg: #0b5ed7;
  --bs-btn-hover-border-color: #0a58ca;
  --bs-btn-active-bg: #0a58ca;
  --bs-btn-active-border-color: #0a53be;
  --bs-btn-disabled-bg: #3477f1;
  --bs-btn-disabled-border-color: #3477f1;
}

.btn-success {
  --bs-btn-color: #011323;
  --bs-btn-bg: #0BE096;
  --bs-btn-border-color: #0BE096;
  --bs-btn-hover-color: #011323;
  --bs-btn-hover-bg: #03d28a;
  --bs-btn-hover-border-color: #03d28a;
  --bs-btn-active-color: #011323;
  --bs-btn-active-bg: #03d28a;
  --bs-btn-active-border-color: #03d28a;
  --bs-btn-disabled-color: #011323;
  --bs-btn-disabled-bg: #0BE096;
  --bs-btn-disabled-border-color: #0BE096;
}

.bg-warning {
  color: rgba(171, 109, 3, 1);
  --bs-warning-rgb: 247, 226, 207;
  text-transform: uppercase;
  display: flex;
  align-items: center;
}

.home-banner {
  height: calc(100vh - 202px);
  max-height: 700px;
  display: flex;
  flex-direction: column;
}

.banner-content {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.home-banner .title {
  font-family: Inter;
  font-size: 40px;
  font-weight: 500;
  /* line-height: 78px; */
  letter-spacing: -0.02em;
  text-align: left;
}

.home-banner .title span {
  color: rgba(35, 199, 142, 1);
  font-weight: 600;
}

.home-banner .sub-title {
  padding: 25px 0;
  color: rgba(118, 118, 118, 1);
  font-size: 20px;
  font-weight: 300;
}

.banner-button {
  display: flex;
  gap: 15px;
}

.banner-button button {
  min-width: 144px;
}

.home-banner .banner-image {
  width: 100%;
  height: 100%;
  background-image: url(./../public/home-banner.png);
  background-position: right;
  background-size: contain;
  background-repeat: no-repeat;
}

.bg-white {
  padding: 100px 0;
  --bs-dark-rgb: 32, 35, 35;
}

.bg-dark {
  padding: 100px 0;
  color: #fff;
  background-color: #151717 !important;
}

.bg-gray {
  background-color: #202323;
}

.home-section-1 .card-body {
  padding: 24px;
  border-radius: 16px;
}

.home-section-1 .title {
  font-size: 45px;
  font-weight: 700;
  line-height: 45px;
}

.home-section-1 .sub-title {
  color: #BFBFBF;
  font-size: 20px;
  font-weight: 300;
  line-height: 28px;
}

.home-section-2 .card-body {
  height: 100%;
  box-shadow: 0px 4px 22px 0px #0000000D;
  padding: 20px;
  border-radius: 12px;
}

.bg-dark .card-body {
  border: 1px solid #2E2E2E;
}

.bg-white .card-body {
  border: 1px solid #EBEBEB;
}

.home-section-2 .card-title {
  font-size: 36px;
  font-weight: 600;
  line-height: 48px;
  letter-spacing: -0.01em;
}

.home-section-2 .card-text {
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  color: #ADADAD;
}

.home-section-3 {
  height: 700px;
}

.home-section-3 .banner-image {
  background-image: url(./../public/copy-trading.png);
  background-size: 150%;
}

.home-section-3 .title {
  font-size: 52px;
  line-height: 64px;
  letter-spacing: -2px;
}

.text-gray {
  color: #767676;
}

.section-title {
  position: relative;
  margin: 40px 0;
  width: max-content;
  font-size: 40px;
  font-weight: 600;
  line-height: 44px;
  letter-spacing: -1px;
}

.section-title.gradient-text::before {
  content: "";
  position: absolute;
  width: 60px;
  height: 60px;
  top: -55px;
  right: -60px;
  background-image: url(./../public/title-bg.svg);
  background-repeat: no-repeat;
}

.title.icon {
  display: flex;
  align-items: center;
  gap: 6px;
}

.title img {
  background-color: #F2ECFD;
  border-radius: 2.86px;
}

.text-success {
  --bs-success-rgb: 11, 224, 150;
}

.nav-underline {
  --bs-nav-link-color: #545F5F;
  --bs-nav-link-hover-color: #fff;
  --bs-nav-underline-link-active-color: #fff;
  --bs-nav-underline-gap: 2rem;
}

.nav-underline .nav-link {
  font-size: 24px;
  font-weight: 500;
  line-height: 32px;
  letter-spacing: -1px;
}

.btn-tab {
  background: #292D2D;
}

.btn-tab button {
  margin: 6px;
  --bs-btn-padding-x: 15px;
  --bs-btn-padding-y: 8px;
  --bs-btn-color: #fff;
  --bs-btn-font-weight: 600;
  --bs-btn-bg: #292D2D;
  --bs-btn-border-color: var(--bs-btn-bg);
  border-radius: 8px !important;
}

.dropdown-menu {
  font-size: 14px;
}

.nav-tabs {
  position: relative;
  z-index: 2;
}

.nav-tabs .nav-link {
  color: #7D7E7E;
}

.nav-tabs .nav-link.active {
  background-color: #F4F5F9;
  border-bottom-color: #F4F5F9;
}

.tab-content {
  position: relative;
  z-index: 1;
  margin-top: -1px;
}

.breadcrumb-item.active {
  color: #fff;
}

.breadcrumb-item+.breadcrumb-item::before {
  color: #fff;
  content: ">";
}