.container {
  --bs-gutter-x: 2.5rem;
}

.home-container {
  /* background: #131517; */
  background: #333;
  color: #FFF;
  width: 100%;
  display: flex;
  min-height: 100vh;
  overflow-x: hidden;
  align-items: center;
  flex-direction: column;
}

.custom-header {
  background: linear-gradient(180deg, #0000, #fff);
  bottom: 0px;
  position: absolute !important;
  width: 100%;
  z-index: 9;
  max-width: none !important;
  display: block !important;
}

.custom-header .home-content {
  align-items: center !important;
}

.home-header {
  padding-bottom: var(--dl-space-space-sixunits);
  background: #fff;
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
}

.home-heading {
  width: 100%;
  display: flex;
  position: relative;
  align-items: center;
  flex-direction: column;
}

.home-notification {
  width: 100%;
}

.home-component {
  text-decoration: none;
}

.home-hero {
  flex: 1;
  width: 100%;
  display: flex;
  position: relative;
  max-width: 1200px;
  align-items: center;
  padding-left: var(--dl-space-space-oneandhalfunits);
  padding-right: var(--dl-space-space-oneandhalfunits);
  flex-direction: row;
  justify-content: space-between;
  padding-top: var(--dl-space-space-sixunits);
}

.home-content {
  gap: var(--dl-space-space-oneandhalfunits);
  flex: 0.5;
  display: flex;
  z-index: 20;
  align-items: flex-start;
  padding-top: var(--dl-space-space-sixunits);
  flex-direction: column;
  padding-bottom: var(--dl-space-space-sixunits);
  justify-content: center;
}

.home-title {
  color: var(--dl-color-primary-100);
  font-size: 64px;
  font-style: normal;
  font-family: Poppins;
  font-weight: 600;
  line-height: 76px;
}

.home-caption {
  color: #FFF;
  font-size: 18px;
  font-style: normal;
  font-family: Poppins;
  font-weight: 400;
  line-height: 27px;
}

.home-hero-buttons {
  gap: var(--dl-space-space-unit);
  display: flex;
  z-index: 15;
  margin-top: var(--dl-space-space-unit);
  align-items: center;
  flex-direction: row;
  justify-content: center;
}

.home-ios-btn {
  gap: var(--dl-space-space-halfunit);
  fill: white;
  color: white;
  cursor: pointer;
  display: flex;
  transition: 0.3s;
  align-items: center;
  padding-top: var(--dl-space-space-oneandhalfunits);
  padding-left: var(--dl-space-space-twounits);
  border-radius: var(--dl-radius-radius-radius8);
  padding-right: var(--dl-space-space-twounits);
  flex-direction: row;
  padding-bottom: var(--dl-space-space-oneandhalfunits);
  justify-content: center;
  background-color: #000000;
}

.home-ios-btn:hover {
  background-color: rgba(0, 0, 0, 0.75);
}

.home-apple {
  width: 16px;
  height: 20px;
}

.home-caption1 {
  font-style: normal;
  font-family: Poppins;
  font-weight: 500;
}

.home-android-btn {
  gap: var(--dl-space-space-halfunit);
  fill: white;
  color: white;
  cursor: pointer;
  display: flex;
  transition: 0.3s;
  align-items: center;
  padding-top: var(--dl-space-space-oneandhalfunits);
  padding-left: var(--dl-space-space-twounits);
  border-radius: var(--dl-radius-radius-radius8);
  padding-right: var(--dl-space-space-twounits);
  flex-direction: row;
  padding-bottom: var(--dl-space-space-oneandhalfunits);
  justify-content: center;
  background-color: #000000;
}

.home-android-btn:hover {
  background-color: rgba(0, 0, 0, 0.75);
}

.home-android {
  width: 18px;
  height: 20px;
}

.home-caption2 {
  font-style: normal;
  font-family: Poppins;
  font-weight: 500;
}

.home-images {
  gap: var(--dl-space-space-oneandhalfunits);
  right: -210px;
  bottom: -300px;
  margin: auto;
  display: flex;
  overflow: hidden;
  position: absolute;
  transform: rotate(-30deg);
  align-items: center;
  flex-direction: row;
  justify-content: center;
}

.home-column {
  gap: var(--dl-space-space-oneandhalfunits);
  height: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}

.home-pasted-image {
  width: 240px;
}

.home-column1 {
  gap: var(--dl-space-space-oneandhalfunits);
  height: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}

.home-pasted-image1 {
  width: 240px;
}

.home-pasted-image2 {
  width: 240px;
}

.home-column2 {
  gap: var(--dl-space-space-oneandhalfunits);
  height: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}

.home-pasted-image3 {
  width: 240px;
}

.home-pasted-image4 {
  width: 240px;
}

.home-video {
  color: white;
  width: 100%;
  display: flex;
  z-index: 10;
  align-items: center;
  padding-top: var(--dl-space-space-sixunits);
  padding-left: var(--dl-space-space-oneandhalfunits);
  padding-right: var(--dl-space-space-oneandhalfunits);
  flex-direction: column;
  padding-bottom: var(--dl-space-space-sixunits);
  background-color: #000000;
}

.home-content01 {
  gap: var(--dl-space-space-fiveunits);
  width: 100%;
  display: flex;
  max-width: 1200px;
  align-items: center;
  flex-direction: column;
}

.home-header1 {
  width: 100%;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}

.home-text {
  width: 640px;
  font-size: 40px;
  font-style: normal;
  font-family: Poppins;
  font-weight: 600;
}

.home-video-container {
  gap: var(--dl-space-space-fiveunits);
  width: 100%;
  display: flex;
  max-width: 900px;
  align-items: flex-start;
  flex-direction: column;
}

.home-video1 {
  width: 100%;
  cursor: pointer;
  overflow: hidden;
  transition: 0.3s;
  border-color: rgba(0, 0, 0, 0);
  border-width: 2px;
}

.home-video1:hover {
  opacity: .5;
  border-color: var(--dl-color-primary-100);
  border-width: 2px;
  border-radius: 8px;
}

.home-heading-container {
  gap: var(--dl-space-space-threeunits);
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}

.home-heading01 {
  gap: var(--dl-space-space-unit);
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}

.home-text01 {
  font-size: 24px;
  font-style: normal;
  font-family: Poppins;
  font-weight: 600;
  line-height: 36px;
}

.home-text02 {
  color: #EAEAEA;
  width: 600px;
  font-family: Poppins;
  line-height: 24px;
}

.home-explore {
  color: #fff;
  cursor: pointer;
  display: flex;
  transition: 0.3s;
  align-items: flex-start;
  border-color: #ffffff;
  border-width: 0px;
  flex-direction: column;
  padding-bottom: 4px;
  border-bottom-width: 1px;
}

.home-explore:hover {
  color: rgba(255, 255, 255, 0.5);
  border-color: rgba(255, 255, 255, 0.3);
}

.home-text03 {
  width: Size-XSmall;
}

.home-stats {
  width: 100%;
  display: flex;
  z-index: 10;
  max-width: 1200px;
  align-items: center;
  padding-top: var(--dl-space-space-fiveunits);
  padding-left: var(--dl-space-space-oneandhalfunits);
  padding-right: var(--dl-space-space-oneandhalfunits);
  flex-direction: row;
  justify-content: center;
}

.home-stat {
  gap: var(--dl-space-space-oneandhalfunits);
  display: flex;
  align-items: center;
  border-color: #DBDBDB;
  padding-left: var(--dl-space-space-threeunits);
  padding-right: var(--dl-space-space-threeunits);
  flex-direction: column;
  padding-bottom: var(--dl-space-space-fiveunits);
  justify-content: center;
  border-right-width: 1px;
  border-bottom-width: 1px;
}

.home-caption3 {
  font-size: 40px;
  font-style: normal;
  font-family: Poppins;
  font-weight: 600;
  line-height: 56px;
}

.home-description {
  width: 100%;
  font-size: 16px;
  max-width: 350px;
  text-align: center;
  font-family: Poppins;
  line-height: 27px;
}

.home-stat1 {
  gap: var(--dl-space-space-oneandhalfunits);
  display: flex;
  align-items: center;
  border-color: #DBDBDB;
  padding-left: var(--dl-space-space-threeunits);
  padding-right: var(--dl-space-space-threeunits);
  flex-direction: column;
  padding-bottom: var(--dl-space-space-fiveunits);
  justify-content: center;
  border-bottom-width: 1px;
}

.home-caption4 {
  font-size: 40px;
  font-style: normal;
  font-family: Poppins;
  font-weight: 600;
  line-height: 56px;
}

.home-description1 {
  width: 100%;
  font-size: 16px;
  max-width: 350px;
  text-align: center;
  font-family: Poppins;
  line-height: 27px;
}

.home-stat2 {
  gap: var(--dl-space-space-oneandhalfunits);
  display: flex;
  align-items: center;
  border-color: #DBDBDB;
  padding-left: var(--dl-space-space-threeunits);
  padding-right: var(--dl-space-space-threeunits);
  flex-direction: column;
  padding-bottom: var(--dl-space-space-fiveunits);
  justify-content: center;
  border-left-width: 1px;
  border-bottom-width: 1px;
}

.home-caption5 {
  font-size: 40px;
  font-style: normal;
  font-family: Poppins;
  font-weight: 600;
  line-height: 56px;
}

.home-description2 {
  width: 100%;
  font-size: 16px;
  max-width: 350px;
  text-align: center;
  font-family: Poppins;
  line-height: 27px;
}

.home-section {
  gap: var(--dl-space-space-sixunits);
  width: 100%;
  display: flex;
  z-index: 10;
  align-items: center;
  flex-direction: row;
  justify-content: center;
}

.home-image {
  width: 400px;
  height: 540px;
  display: flex;
  position: relative;
  align-items: center;
  flex-direction: row;
  background-size: contain;
  justify-content: flex-end;
  background-image: url("../../public/group%201277.svg");
  background-repeat: no-repeat;
  background-position: center;
}

.home-image-highlight {
  top: 0px;
  right: 0px;
  margin: auto;
  display: flex;
  position: absolute;
  align-items: center;
  padding-top: var(--dl-space-space-oneandhalfunits);
  padding-left: var(--dl-space-space-twounits);
  padding-right: var(--dl-space-space-twounits);
  flex-direction: row;
  padding-bottom: var(--dl-space-space-oneandhalfunits);
  justify-content: center;
  background-color: #375EE0;
}

.home-text04 {
  color: rgb(193, 255, 252);
  width: 100%;
  max-width: 90px;
  font-style: normal;
  text-align: center;
  font-family: Poppins;
  font-weight: 500;
}

.home-content02 {
  gap: var(--dl-space-space-oneandhalfunits);
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: center;
}

.home-text07 {
  font-size: 40px;
  max-width: 535px;
  font-style: normal;
  font-family: Poppins;
  font-weight: 600;
  line-height: 56px;
}

.home-explore1 {
  cursor: pointer;
  display: flex;
  margin-top: var(--dl-space-space-unit);
  transition: 0.3s;
  align-items: flex-start;
  border-width: 0px;
  flex-direction: column;
  padding-bottom: 4px;
  border-bottom-width: 1px;
}

.home-explore1:hover {
  color: rgba(0, 0, 0, 0.5);
  border-color: rgba(0, 0, 0, 0.3);
}

.home-section1 {
  gap: var(--dl-space-space-sixunits);
  width: 100%;
  display: flex;
  z-index: 10;
  align-items: center;
  padding-top: var(--dl-space-space-sixunits);
  flex-direction: row;
  justify-content: center;
}

.home-content03 {
  gap: var(--dl-space-space-oneandhalfunits);
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: center;
}

.home-heading02 {
  gap: var(--dl-space-space-unit);
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: center;
}

.home-text10 {
  font-size: 18px;
  max-width: 535px;
  font-family: Poppins;
  line-height: 27px;
}

.home-content04 {
  gap: var(--dl-space-space-threeunits);
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: center;
}

.home-points {
  gap: var(--dl-space-space-unit);
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: center;
}

.home-navlink1 {
  display: contents;
}

.home-get-started {
  display: flex;
  transition: 0.3s;
  align-items: flex-start;
  padding-top: var(--dl-space-space-oneandhalfunits);
  padding-left: var(--dl-space-space-twounits);
  border-radius: var(--dl-radius-radius-radius8);
  padding-right: var(--dl-space-space-twounits);
  flex-direction: row;
  padding-bottom: var(--dl-space-space-oneandhalfunits);
  justify-content: center;
  text-decoration: none;
  background-color: var(--dl-color-primary-100);
}

.home-get-started:hover {
  /* background-color: rgba(173, 226, 223, 0.5); */
}

.home-sign-up {
  font-style: normal;
  font-weight: 500;
}

.home-image1, .home-image-register, .home-banner4 {
  width: 370px;
  height: 600px;
  display: flex;
  position: relative;
  align-items: center;
  flex-direction: row;
  /* background-size: cover; */
  justify-content: flex-end;
  background-image: url("../../public/p_banner1.svg");
  background-repeat: no-repeat;
  background-position: center;
}

.home-image-register {
  background-image: url("../../public/register.svg");

}

.home-banner4 {
  width: 60% !important;
  background-size: contain;
  background-image: url("../../public/banner1.jpg");

}

.home-section2 {
  gap: var(--dl-space-space-sixunits);
  width: 100%;
  display: flex;
  z-index: 10;
  align-items: flex-start;
  padding-top: var(--dl-space-space-sixunits);
  flex-direction: row;
  padding-bottom: var(--dl-space-space-sixunits);
  justify-content: center;
}

.home-image2 {
  width: 400px;
  height: 590px;
  display: flex;
  position: relative;
  align-items: center;
  flex-direction: row;
  background-size: cover;
  justify-content: center;
  background-image: url("../../public/group%201281.svg");
  background-repeat: no-repeat;
  background-position: center;
}

.home-image-overlay {
  right: 0px;
  width: 180px;
  bottom: 0px;
  height: 320px;
  margin: auto;
  display: flex;
  position: absolute;
  align-items: center;
  flex-direction: row;
  background-size: cover;
  justify-content: center;
  background-image: url("../../public/pastedimage-v31-1500h.png");
}

.home-content05 {
  gap: var(--dl-space-space-oneandhalfunits);
  display: flex;
  margin-top: var(--dl-space-space-fiveunits);
  align-items: flex-start;
  flex-direction: column;
}

.home-text11 {
  font-size: 40px;
  max-width: 640px;
  font-style: normal;
  font-family: Poppins;
  font-weight: 600;
  line-height: 56px;
}

.home-banner-container {
  width: 100%;
  display: flex;
  align-items: center;
  padding-left: var(--dl-space-space-unit);
  padding-right: var(--dl-space-space-unit);
  flex-direction: column;
}

.home-overlay {
  gap: var(--dl-space-space-threeunits);
  width: 100%;
  height: 100%;
  display: flex;
  align-items: flex-start;
  padding-top: var(--dl-space-space-fiveunits);
  padding-left: var(--dl-space-space-fiveunits);
  padding-right: var(--dl-space-space-fiveunits);
  flex-direction: column;
  padding-bottom: var(--dl-space-space-fiveunits);
  background-size: cover;
  justify-content: center;
  background-image: url("../../public/mask%20group.svg");
}

.home-text14 {
  color: rgb(193, 255, 252);
  width: 100%;
  font-size: 40px;
  max-width: 490px;
  font-style: normal;
  font-family: Poppins;
  font-weight: 600;
}

.home-book-btn {
  cursor: pointer;
  display: flex;
  transition: 0.3s;
  align-items: flex-start;
  padding-top: var(--dl-space-space-oneandhalfunits);
  padding-left: var(--dl-space-space-twounits);
  border-radius: var(--dl-radius-radius-radius8);
  padding-right: var(--dl-space-space-twounits);
  flex-direction: column;
  padding-bottom: var(--dl-space-space-oneandhalfunits);
  justify-content: center;
  background-color: #000000;
}

.home-book-btn:hover {
  background-color: rgba(0, 0, 0, 0.5);
}

.home-text15 {
  color: #ffffff;
  width: 100%;
  max-width: 490px;
  font-style: normal;
  font-weight: 500;
}

.home-pasted-image5 {
  display: none;
}

.home-features {
  margin-bottom: 65px;
  gap: var(--dl-space-space-fiveunits);
  width: 100%;
  display: flex;
  max-width: 1200px;
  align-items: flex-start;
  padding-top: 50px;
  padding-left: var(--dl-space-space-oneandhalfunits);
  border-radius: 16px;
  padding-right: var(--dl-space-space-oneandhalfunits);
  flex-direction: column;
  padding-bottom: 50px;
  justify-content: flex-start;
  background: #333;
  margin-top: var(--dl-space-space-sixunits);
}

.home-header2 {
  gap: var(--dl-space-space-oneandhalfunits);
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: flex-start;
}

.home-tag {
  display: flex;
  align-items: center;
  padding-top: var(--dl-space-space-halfunit);
  padding-left: var(--dl-space-space-unit);
  border-radius: var(--dl-radius-radius-radius8);
  padding-right: var(--dl-space-space-unit);
  flex-direction: column;
  padding-bottom: var(--dl-space-space-halfunit);
  justify-content: center;
  background-color: var(--dl-color-primary-100);
}

.home-text16 {
  font-style: normal;
  font-weight: 500;
}

.home-heading03 {
  gap: var(--dl-space-space-unit);
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: flex-start;
}

.home-text17 {
  width: 100%;
  font-size: 40px;
  max-width: 400px;
  font-style: normal;
  font-weight: 600;
  line-height: 56px;
}

.home-text18 {
  width: 100%;
  font-size: 18px;
  max-width: 600px;
  line-height: 27px;
}

.home-feature-list {
  gap: var(--dl-space-space-fourunits);
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
  flex-direction: row;
  justify-content: flex-start;
}

.home-tag1 {
  display: flex;
  align-items: center;
  padding-top: var(--dl-space-space-halfunit);
  padding-left: var(--dl-space-space-unit);
  border-radius: var(--dl-radius-radius-radius8);
  padding-right: var(--dl-space-space-unit);
  flex-direction: column;
  padding-bottom: var(--dl-space-space-halfunit);
  justify-content: center;
  background-color: var(--dl-color-primary-100);
}

.home-text19 {
  font-style: normal;
  font-weight: 500;
}

.home-plans {
  background: #000;
  flex: 1;
  height: 100%;
  display: grid;
  padding-top: var(--dl-space-space-twounits);
  padding-left: TwoUnits;
  padding-bottom: var(--dl-space-space-twounits);
  /* background-color: #ffffff; */
  grid-template-columns: auto auto auto;
}

.home-plan {
  width: 100%;
  height: 100%;
  display: flex;
  max-width: 300px;
  align-items: flex-start;
  border-color: #DBDBDB;
  padding-left: var(--dl-space-space-twounits);
  padding-right: var(--dl-space-space-twounits);
  flex-direction: column;
  justify-content: flex-start;
  border-right-width: 1px;
}

.home-top {
  gap: var(--dl-space-space-oneandhalfunits);
  display: flex;
  align-items: flex-start;
  border-color: #DBDBDB;
  flex-direction: column;
  padding-bottom: var(--dl-space-space-twounits);
  justify-content: flex-start;
  border-bottom-width: 1px;
}

.home-heading05 {
  gap: var(--dl-space-space-unit);
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: flex-start;
}

.home-text21 {
  font-size: 20px;
  font-style: normal;
  font-family: Poppins;
  font-weight: 600;
}

.home-text22 {
  font-family: Poppins;
  line-height: 24px;
}

.home-cost {
  width: 100%;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: flex-start;
}

.home-text23 {
  font-size: 40px;
  font-style: normal;
  font-family: Poppins;
  font-weight: 600;
  line-height: 40px;
}

.home-bottom {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: flex-start;
  padding-top: var(--dl-space-space-twounits);
  flex-direction: column;
  justify-content: space-between;
}

.home-check-list {
  gap: var(--dl-space-space-halfunit);
  width: 100%;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  padding-bottom: var(--dl-space-space-threeunits);
  justify-content: flex-start;
}

.home-button {
  width: 100%;
  cursor: pointer;
  display: flex;
  transition: 0.3s;
  align-items: center;
  padding-top: var(--dl-space-space-oneandhalfunits);
  padding-left: var(--dl-space-space-twounits);
  border-radius: var(--dl-radius-radius-radius8);
  padding-right: var(--dl-space-space-twounits);
  flex-direction: column;
  padding-bottom: var(--dl-space-space-oneandhalfunits);
  justify-content: center;
  background-color: var(--dl-color-primary-100);
}

.home-button:hover {
  background-color: rgba(173, 226, 223, 0.5);
}

.home-text24 {
  font-style: normal;
  font-weight: 500;
}

.home-plan1 {
  width: 100%;
  height: 100%;
  display: flex;
  max-width: 300px;
  align-items: flex-start;
  border-color: #DBDBDB;
  padding-left: var(--dl-space-space-twounits);
  padding-right: var(--dl-space-space-twounits);
  flex-direction: column;
  justify-content: space-between;
  border-right-width: 1px;
}

.home-top1 {
  gap: var(--dl-space-space-oneandhalfunits);
  display: flex;
  align-items: flex-start;
  border-color: #DBDBDB;
  flex-direction: column;
  padding-bottom: var(--dl-space-space-twounits);
  justify-content: flex-start;
  border-bottom-width: 1px;
}

.home-heading06 {
  gap: var(--dl-space-space-unit);
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: flex-start;
}

.home-text25 {
  font-size: 20px;
  font-style: normal;
  font-family: Poppins;
  font-weight: 600;
}

.home-text26 {
  font-family: Poppins;
  line-height: 24px;
}

.home-cost1 {
  gap: var(--dl-space-space-halfunit);
  width: 100%;
  display: flex;
  align-items: flex-end;
  flex-direction: row;
  justify-content: flex-start;
}

.home-text27 {
  font-size: 40px;
  font-style: normal;
  font-family: Poppins;
  font-weight: 600;
  line-height: 40px;
}

.home-text28 {
  font-family: Poppins;
}

.home-bottom1 {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: flex-start;
  padding-top: var(--dl-space-space-twounits);
  flex-direction: column;
  justify-content: space-between;
}

.home-check-list1 {
  gap: var(--dl-space-space-halfunit);
  width: 100%;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  padding-bottom: var(--dl-space-space-threeunits);
  justify-content: flex-start;
}

.home-button1 {
  width: 100%;
  cursor: pointer;
  display: flex;
  transition: 0.3s;
  align-items: center;
  padding-top: var(--dl-space-space-oneandhalfunits);
  padding-left: var(--dl-space-space-twounits);
  border-radius: var(--dl-radius-radius-radius8);
  padding-right: var(--dl-space-space-twounits);
  flex-direction: column;
  padding-bottom: var(--dl-space-space-oneandhalfunits);
  justify-content: center;
  background-color: var(--dl-color-primary-100);
}

.home-button1:hover {
  background-color: rgba(173, 226, 223, 0.5);
}

.home-text29 {
  font-style: normal;
  font-weight: 500;
}

.home-plan2 {
  width: 100%;
  height: 100%;
  display: flex;
  max-width: 300px;
  align-items: flex-start;
  padding-left: var(--dl-space-space-twounits);
  padding-right: var(--dl-space-space-twounits);
  flex-direction: column;
  justify-content: space-between;
}

.home-top2 {
  gap: var(--dl-space-space-oneandhalfunits);
  display: flex;
  align-items: flex-start;
  border-color: #DBDBDB;
  flex-direction: column;
  padding-bottom: var(--dl-space-space-twounits);
  justify-content: flex-start;
  border-bottom-width: 1px;
}

.home-heading07 {
  gap: var(--dl-space-space-unit);
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: flex-start;
}

.home-text30 {
  font-size: 20px;
  font-style: normal;
  font-family: Poppins;
  font-weight: 600;
}

.home-text31 {
  font-family: Poppins;
  line-height: 24px;
}

.home-cost2 {
  gap: var(--dl-space-space-halfunit);
  width: 100%;
  display: flex;
  align-items: flex-end;
  flex-direction: row;
  justify-content: flex-start;
}

.home-text32 {
  font-size: 40px;
  font-style: normal;
  font-family: Poppins;
  font-weight: 600;
  line-height: 40px;
}

.home-text33 {
  font-family: Poppins;
}

.home-bottom2 {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: flex-start;
  padding-top: var(--dl-space-space-twounits);
  flex-direction: column;
  justify-content: space-between;
}

.home-check-list2 {
  gap: var(--dl-space-space-halfunit);
  width: 100%;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  padding-bottom: var(--dl-space-space-threeunits);
  justify-content: flex-start;
}

.home-button2 {
  width: 100%;
  cursor: pointer;
  display: flex;
  transition: 0.3s;
  align-items: center;
  padding-top: var(--dl-space-space-oneandhalfunits);
  padding-left: var(--dl-space-space-twounits);
  border-radius: var(--dl-radius-radius-radius8);
  padding-right: var(--dl-space-space-twounits);
  flex-direction: column;
  padding-bottom: var(--dl-space-space-oneandhalfunits);
  justify-content: center;
  background-color: var(--dl-color-primary-100);

}

.home-button2:hover {
  background-color: rgba(173, 226, 223, 0.5);
}

.home-text34 {
  font-style: normal;
  font-weight: 500;
}

.home-expand {
  flex: 0.3;
  height: 100%;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: space-between;
}

.home-overlay1 {
  flex: 1;
  display: flex;
  padding: var(--dl-space-space-twounits);
  align-items: center;
  flex-direction: column;
  background-size: cover;
  justify-content: space-between;
  background-image: url("../../public/planbackground.svg");
}

.home-header4 {
  gap: var(--dl-space-space-twounits);
  width: 100%;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: flex-start;
}

.home-heading08 {
  gap: var(--dl-space-space-unit);
  width: 100%;
  display: flex;
  max-width: 256px;
  align-items: flex-start;
  flex-direction: column;
  justify-content: flex-start;
}

.home-text35 {
  color: #ffffff;
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
}

.home-text36 {
  color: #C1FFFC;
  font-size: 24px;
  font-style: normal;
  font-family: Poppins;
  font-weight: 500;
  line-height: 31px;
}

.home-check-list3 {
  gap: var(--dl-space-space-halfunit);
  width: 100%;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: flex-start;
}

.home-check {
  gap: var(--dl-space-space-halfunit);
  display: flex;
  position: relative;
  align-items: center;
  flex-direction: row;
  justify-content: flex-start;
}

.home-icon {
  fill: #ffffff;
  width: 18px;
  height: 18px;
}

.home-text37 {
  color: #ffffff;
  font-size: 14px;
  font-family: Poppins;
  line-height: 24px;
}

.home-check1 {
  gap: var(--dl-space-space-halfunit);
  display: flex;
  position: relative;
  align-items: center;
  flex-direction: row;
  justify-content: flex-start;
}

.home-icon02 {
  fill: #ffffff;
  width: 18px;
  height: 18px;
}

.home-text38 {
  color: rgb(255, 255, 255);
  font-size: 14px;
  font-family: Poppins;
  line-height: 24px;
}

.home-check2 {
  gap: var(--dl-space-space-halfunit);
  display: flex;
  position: relative;
  align-items: center;
  flex-direction: row;
  justify-content: flex-start;
}

.home-icon04 {
  fill: #ffffff;
  width: 18px;
  height: 18px;
}

.home-text39 {
  color: rgb(255, 255, 255);
  font-size: 14px;
  font-family: Poppins;
  line-height: 24px;
}

.home-button3 {
  width: 100%;
  cursor: pointer;
  display: flex;
  transition: 0.3s;
  align-items: center;
  padding-top: var(--dl-space-space-oneandhalfunits);
  padding-left: var(--dl-space-space-twounits);
  border-radius: var(--dl-radius-radius-radius8);
  padding-right: var(--dl-space-space-twounits);
  flex-direction: column;
  padding-bottom: var(--dl-space-space-oneandhalfunits);
  justify-content: center;
  background-color: #000000;
}

.home-button3:hover {
  background-color: rgba(0, 0, 0, 0.5);
}

.home-text40 {
  color: rgb(255, 255, 255);
  font-style: normal;
  font-weight: 500;
}

.home-plans1 {
  width: 100%;
  height: 100%;
  display: none;
  align-items: center;
  flex-direction: column;
}

.home-plan3 {
  width: 100%;
  height: 100%;
  display: flex;
  max-width: 300px;
  align-items: flex-start;
  border-color: #DBDBDB;
  padding-left: var(--dl-space-space-twounits);
  padding-right: var(--dl-space-space-twounits);
  flex-direction: column;
  justify-content: flex-start;
  border-right-width: 1px;
}

.home-top3 {
  gap: var(--dl-space-space-oneandhalfunits);
  display: flex;
  align-items: flex-start;
  border-color: #DBDBDB;
  flex-direction: column;
  padding-bottom: var(--dl-space-space-twounits);
  justify-content: flex-start;
  border-bottom-width: 1px;
}

.home-heading09 {
  gap: var(--dl-space-space-unit);
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: flex-start;
}

.home-text43 {
  font-size: 20px;
  font-style: normal;
  font-family: Poppins;
  font-weight: 600;
}

.home-text44 {
  font-family: Poppins;
  line-height: 24px;
}

.home-cost3 {
  width: 100%;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: flex-start;
}

.home-text45 {
  font-size: 40px;
  font-style: normal;
  font-family: Poppins;
  font-weight: 600;
  line-height: 40px;
}

.home-bottom3 {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: flex-start;
  padding-top: var(--dl-space-space-twounits);
  flex-direction: column;
  justify-content: space-between;
}

.home-check-list4 {
  gap: var(--dl-space-space-halfunit);
  width: 100%;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  padding-bottom: var(--dl-space-space-threeunits);
  justify-content: flex-start;
}

.home-button4 {
  width: 100%;
  cursor: pointer;
  display: flex;
  transition: 0.3s;
  align-items: center;
  padding-top: var(--dl-space-space-oneandhalfunits);
  padding-left: var(--dl-space-space-twounits);
  border-radius: var(--dl-radius-radius-radius8);
  padding-right: var(--dl-space-space-twounits);
  flex-direction: column;
  padding-bottom: var(--dl-space-space-oneandhalfunits);
  justify-content: center;
  background-color: var(--dl-color-primary-100);

}

.home-button4:hover {
  background-color: rgba(173, 226, 223, 0.5);
}

.home-text46 {
  font-style: normal;
  font-weight: 500;
}

.home-plan4 {
  width: 100%;
  height: 100%;
  display: flex;
  max-width: 300px;
  align-items: flex-start;
  border-color: #DBDBDB;
  padding-left: var(--dl-space-space-twounits);
  padding-right: var(--dl-space-space-twounits);
  flex-direction: column;
  justify-content: space-between;
  border-right-width: 1px;
}

.home-top4 {
  gap: var(--dl-space-space-oneandhalfunits);
  display: flex;
  align-items: flex-start;
  border-color: #DBDBDB;
  flex-direction: column;
  padding-bottom: var(--dl-space-space-twounits);
  justify-content: flex-start;
  border-bottom-width: 1px;
}

.home-heading10 {
  gap: var(--dl-space-space-unit);
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: flex-start;
}

.home-text47 {
  font-size: 20px;
  font-style: normal;
  font-family: Poppins;
  font-weight: 600;
}

.home-text48 {
  font-family: Poppins;
  line-height: 24px;
}

.home-cost4 {
  gap: var(--dl-space-space-halfunit);
  width: 100%;
  display: flex;
  align-items: flex-end;
  flex-direction: row;
  justify-content: flex-start;
}

.home-text49 {
  font-size: 40px;
  font-style: normal;
  font-family: Poppins;
  font-weight: 600;
  line-height: 40px;
}

.home-text50 {
  font-family: Poppins;
}

.home-bottom4 {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: flex-start;
  padding-top: var(--dl-space-space-twounits);
  flex-direction: column;
  justify-content: space-between;
}

.home-check-list5 {
  gap: var(--dl-space-space-halfunit);
  width: 100%;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  padding-bottom: var(--dl-space-space-threeunits);
  justify-content: flex-start;
}

.home-button5 {
  width: 100%;
  cursor: pointer;
  display: flex;
  transition: 0.3s;
  align-items: center;
  padding-top: var(--dl-space-space-oneandhalfunits);
  padding-left: var(--dl-space-space-twounits);
  border-radius: var(--dl-radius-radius-radius8);
  padding-right: var(--dl-space-space-twounits);
  flex-direction: column;
  padding-bottom: var(--dl-space-space-oneandhalfunits);
  justify-content: center;
  background-color: var(--dl-color-primary-100);

}

.home-button5:hover {
  background-color: rgba(173, 226, 223, 0.5);
}

.home-text51 {
  font-style: normal;
  font-weight: 500;
}

.home-plan5 {
  width: 100%;
  height: 100%;
  display: flex;
  max-width: 300px;
  align-items: flex-start;
  border-color: #DBDBDB;
  padding-left: var(--dl-space-space-twounits);
  padding-right: var(--dl-space-space-twounits);
  flex-direction: column;
  justify-content: space-between;
  border-right-width: 1px;
}

.home-top5 {
  gap: var(--dl-space-space-oneandhalfunits);
  display: flex;
  align-items: flex-start;
  border-color: #DBDBDB;
  flex-direction: column;
  padding-bottom: var(--dl-space-space-twounits);
  justify-content: flex-start;
  border-bottom-width: 1px;
}

.home-heading11 {
  gap: var(--dl-space-space-unit);
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: flex-start;
}

.home-text52 {
  font-size: 20px;
  font-style: normal;
  font-family: Poppins;
  font-weight: 600;
}

.home-text53 {
  font-family: Poppins;
  line-height: 24px;
}

.home-cost5 {
  gap: var(--dl-space-space-halfunit);
  width: 100%;
  display: flex;
  align-items: flex-end;
  flex-direction: row;
  justify-content: flex-start;
}

.home-text54 {
  font-size: 40px;
  font-style: normal;
  font-family: Poppins;
  font-weight: 600;
  line-height: 40px;
}

.home-text55 {
  font-family: Poppins;
}

.home-bottom5 {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: flex-start;
  padding-top: var(--dl-space-space-twounits);
  flex-direction: column;
  justify-content: space-between;
}

.home-check-list6 {
  gap: var(--dl-space-space-halfunit);
  width: 100%;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  padding-bottom: var(--dl-space-space-threeunits);
  justify-content: flex-start;
}

.home-button6 {
  width: 100%;
  cursor: pointer;
  display: flex;
  transition: 0.3s;
  align-items: center;
  padding-top: var(--dl-space-space-oneandhalfunits);
  padding-left: var(--dl-space-space-twounits);
  border-radius: var(--dl-radius-radius-radius8);
  padding-right: var(--dl-space-space-twounits);
  flex-direction: column;
  padding-bottom: var(--dl-space-space-oneandhalfunits);
  justify-content: center;
  background-color: var(--dl-color-primary-100);

}

.home-button6:hover {
  background-color: rgba(173, 226, 223, 0.5);
}

.home-text56 {
  font-style: normal;
  font-weight: 500;
}

.home-expand1 {
  flex: 0.3;
  height: 100%;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: space-between;
}

.home-overlay2 {
  flex: 1;
  display: flex;
  padding: var(--dl-space-space-twounits);
  align-items: center;
  flex-direction: column;
  background-size: cover;
  justify-content: space-between;
  background-image: url("../../public/planbackground.svg");
}

.home-header5 {
  gap: var(--dl-space-space-twounits);
  width: 100%;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: flex-start;
}

.home-heading12 {
  gap: var(--dl-space-space-unit);
  width: 100%;
  display: flex;
  max-width: 256px;
  align-items: flex-start;
  flex-direction: column;
  justify-content: flex-start;
}

.home-text57 {
  color: #ffffff;
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
}

.home-text58 {
  color: #C1FFFC;
  font-size: 24px;
  font-style: normal;
  font-family: Poppins;
  font-weight: 500;
  line-height: 31px;
}

.home-check-list7 {
  gap: var(--dl-space-space-halfunit);
  width: 100%;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: flex-start;
}

.home-check3 {
  gap: var(--dl-space-space-halfunit);
  display: flex;
  position: relative;
  align-items: center;
  flex-direction: row;
  justify-content: flex-start;
}

.home-icon06 {
  fill: #ffffff;
  width: 18px;
  height: 18px;
}

.home-text59 {
  color: #ffffff;
  font-size: 14px;
  font-family: Poppins;
  line-height: 24px;
}

.home-check4 {
  gap: var(--dl-space-space-halfunit);
  display: flex;
  position: relative;
  align-items: center;
  flex-direction: row;
  justify-content: flex-start;
}

.home-icon08 {
  fill: #ffffff;
  width: 18px;
  height: 18px;
}

.home-text60 {
  color: rgb(255, 255, 255);
  font-size: 14px;
  font-family: Poppins;
  line-height: 24px;
}

.home-check5 {
  gap: var(--dl-space-space-halfunit);
  display: flex;
  position: relative;
  align-items: center;
  flex-direction: row;
  justify-content: flex-start;
}

.home-icon10 {
  fill: #ffffff;
  width: 18px;
  height: 18px;
}

.home-text61 {
  color: rgb(255, 255, 255);
  font-size: 14px;
  font-family: Poppins;
  line-height: 24px;
}

.home-button7 {
  width: 100%;
  cursor: pointer;
  display: flex;
  transition: 0.3s;
  align-items: center;
  padding-top: var(--dl-space-space-oneandhalfunits);
  padding-left: var(--dl-space-space-twounits);
  border-radius: var(--dl-radius-radius-radius8);
  padding-right: var(--dl-space-space-twounits);
  flex-direction: column;
  padding-bottom: var(--dl-space-space-oneandhalfunits);
  justify-content: center;
  background-color: #000000;
}

.home-button7:hover {
  background-color: rgba(0, 0, 0, 0.5);
}

.home-text62 {
  color: rgb(255, 255, 255);
  font-style: normal;
  font-weight: 500;
}

.home-help {
  gap: var(--dl-space-space-halfunit);
  display: flex;
  align-items: flex-start;
  padding-top: var(--dl-space-space-threeunits);
  flex-direction: row;
}

.home-text65 {
  font-family: Poppins;
}

.home-explore2 {
  cursor: pointer;
  display: flex;
  transition: 0.3s;
  align-items: flex-start;
  border-width: 0px;
  flex-direction: column;
  padding-bottom: 4px;
  border-bottom-width: 1px;
}

.home-explore2:hover {
  color: rgba(0, 0, 0, 0.5);
  border-color: rgba(0, 0, 0, 0.3);
}

.home-text66 {
  font-style: normal;
  font-weight: 600;
}

.home-logo-container {
  display: flex;
  padding: var(--dl-space-space-unit);
  align-self: center;
  align-items: center;
  border-radius: var(--dl-radius-radius-radius8);
  margin-bottom: var(--dl-space-space-threeunits);
  flex-direction: column;
  background-color: #375EE0;
}

.home-logo {
  width: 25px;
  height: 24px;
}

.home-content07 {
  display: flex;
  position: relative;
  align-items: stretch;
  flex-direction: column;
}

.home-quotes {
  display: flex;
  align-items: center;
  flex-direction: column;
}

.home-buttons {
  width: 100%;
  display: flex;
  z-index: 100;
  max-width: 500px;
  align-self: center;
  margin-top: -55px;
  align-items: flex-end;
  flex-direction: row;
  justify-content: space-between;
}

.home-left {
  fill: #DBDBDB;
  cursor: pointer;
  display: flex;
  padding: var(--dl-space-space-unit);
  transition: 0.3s;
  align-items: center;
  border-color: #DBDBDB;
  border-width: 1px;
  border-radius: var(--dl-radius-radius-round);
  flex-direction: row;
}

.home-left:hover {
  fill: #000;
  border-color: #000;
}

.home-icon12 {
  width: 19px;
  height: 19px;
}

.home-right {
  fill: #DBDBDB;
  cursor: pointer;
  display: flex;
  padding: var(--dl-space-space-unit);
  transition: 0.3s;
  align-items: center;
  border-color: #DBDBDB;
  border-width: 1px;
  border-radius: var(--dl-radius-radius-round);
  flex-direction: row;
}

.home-right:hover {
  fill: #FFF;
  border-color: #FFF;
}

.home-icon14 {
  width: 19px;
  height: 19px;
}

.home-container2 {
  display: contents;
}

.home-faq {
  padding-top: var(--dl-space-space-sixunits);
}

.home-tag2 {
  display: flex;
  align-items: center;
  padding-top: var(--dl-space-space-halfunit);
  padding-left: var(--dl-space-space-unit);
  border-radius: var(--dl-radius-radius-radius8);
  padding-right: var(--dl-space-space-unit);
  flex-direction: column;
  padding-bottom: var(--dl-space-space-halfunit);
  justify-content: center;
  background-color: var(--dl-color-primary-100);
}

.home-text67 {
  font-style: normal;
  font-weight: 500;
}

.home-column3 {
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
}

.home-title1 {
  font-size: 18px;
  font-style: normal;
  font-family: Poppins;
  font-weight: 500;
}

.home-description3 {
  font-family: Poppins;
  line-height: 24px;
}

.home-title2 {
  font-size: 18px;
  font-style: normal;
  font-family: Poppins;
  font-weight: 500;
}

.home-description4 {
  font-family: Poppins;
  line-height: 24px;
}

.home-question2 {
  border-bottom-width: 0px;
}

.home-title3 {
  font-size: 18px;
  font-style: normal;
  font-family: Poppins;
  font-weight: 500;
}

.home-description5 {
  font-family: Poppins;
  line-height: 24px;
}

.home-column4 {
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
}

.home-question3 {
  border-right-width: 0px;
}

.home-title4 {
  font-size: 18px;
  font-style: normal;
  font-family: Poppins;
  font-weight: 500;
}

.home-description6 {
  font-family: Poppins;
  line-height: 24px;
}

.home-question4 {
  border-right-width: 0px;
}

.home-title5 {
  font-size: 18px;
  font-style: normal;
  font-family: Poppins;
  font-weight: 500;
}

.home-description7 {
  font-family: Poppins;
  line-height: 24px;
}

.home-question5 {
  border-right-width: 0px;
  border-bottom-width: 0px;
}

.home-title6 {
  font-size: 18px;
  font-style: normal;
  font-family: Poppins;
  font-weight: 500;
}

.home-description8 {
  font-family: Poppins;
  line-height: 24px;
}

.home-get-started1 {
  width: 100%;
  display: flex;
  align-items: center;
  padding-top: var(--dl-space-space-sixunits);
  padding-left: var(--dl-space-space-oneandhalfunits);
  padding-right: var(--dl-space-space-oneandhalfunits);
  flex-direction: column;
  padding-bottom: var(--dl-space-space-sixunits);
  background-color: #0be096;
}

.home-content09 {
  gap: var(--dl-space-space-threeunits);
  display: flex;
  max-width: 600px;
  align-items: center;
  flex-direction: column;
}

.home-heading14 {
  gap: var(--dl-space-space-unit);
  display: flex;
  align-items: center;
  flex-direction: column;
}

.home-text77 {
  color: #fff;
  font-size: 64px;
  font-style: normal;
  text-align: center;
  font-family: Poppins;
  font-weight: 600;
}

.home-text78 {
  color: #ffffff;
  font-size: 20px;
  text-align: center;
  font-family: Poppins;
  line-height: 30px;
}

.home-hero-buttons1 {
  gap: var(--dl-space-space-unit);
  display: flex;
  z-index: 15;
  align-items: center;
  flex-direction: row;
  justify-content: center;
}

.home-ios-btn1 {
  gap: var(--dl-space-space-halfunit);
  fill: white;
  color: white;
  cursor: pointer;
  display: flex;
  transition: 0.3s;
  align-items: center;
  padding-top: var(--dl-space-space-oneandhalfunits);
  padding-left: var(--dl-space-space-twounits);
  border-radius: var(--dl-radius-radius-radius8);
  padding-right: var(--dl-space-space-twounits);
  flex-direction: row;
  padding-bottom: var(--dl-space-space-oneandhalfunits);
  justify-content: center;
  background-color: #000000;
}

.home-ios-btn1:hover {
  background-color: rgba(0, 0, 0, 0.75);
}

.home-apple1 {
  width: 16px;
  height: 20px;
}

.home-caption6 {
  font-style: normal;
  font-family: Poppins;
  font-weight: 500;
}

.home-android-btn1 {
  gap: var(--dl-space-space-halfunit);
  fill: white;
  color: white;
  cursor: pointer;
  display: flex;
  transition: 0.3s;
  align-items: center;
  padding-top: var(--dl-space-space-oneandhalfunits);
  padding-left: var(--dl-space-space-twounits);
  border-radius: var(--dl-radius-radius-radius8);
  padding-right: var(--dl-space-space-twounits);
  flex-direction: row;
  padding-bottom: var(--dl-space-space-oneandhalfunits);
  justify-content: center;
  background-color: #000000;
}

.home-android-btn1:hover {
  background-color: rgba(0, 0, 0, 0.75);
}

.home-android1 {
  width: 18px;
  height: 20px;
}

.home-caption7 {
  font-style: normal;
  font-family: Poppins;
  font-weight: 500;
}

.legal_container h3 {
  margin: 25px 0 0 0;
  font-size: 24px;
  font-weight: 600;
  line-height: 28px;
  letter-spacing: -0.005em;
}

.legal_container p, .legal_container ul {
  color: #979797;
  font-size: 20px;
  font-weight: 400;
  line-height: 36px;
  letter-spacing: -0.01em;
}


@media(max-width: 991px) {
  .home-hero {
    flex-direction: column;
  }

  .home-content {
    gap: 10px;
    flex: 0 0 auto;
    padding-top: var(--dl-space-space-unit);
    padding-bottom: 0px;
  }

  .home-title {
    font-size: 50px;
    text-align: left;
    line-height: 58px;
    color: #FFF;
  }

  .home-caption {
    font-size: 16px;
  }

  .home-hero-buttons {
    width: 100%;
  }

  .home-ios-btn {
    flex: 1;
  }

  .home-android-btn {
    flex: 1;
  }

  .home-images {
    left: 0px;
    right: -100px;
    bottom: -325px;
    margin: auto;
  }

  .home-pasted-image {
    width: 200px;
  }

  .home-pasted-image1 {
    width: 200px;
  }

  .home-pasted-image2 {
    width: 200px;
  }

  .home-pasted-image3 {
    width: 200px;
  }

  .home-pasted-image4 {
    width: 200px;
  }

  .home-content01 {
    gap: var(--dl-space-space-threeunits);
  }

  .home-stats {
    padding-top: var(--dl-space-space-threeunits);
    flex-direction: column;
  }

  .home-stat {
    gap: var(--dl-space-space-halfunit);
    width: 100%;
    /* padding-bottom: var(--dl-space-space-threeunits); */
    border-right-width: 0px;
    /* padding-top: 15px; */
    /* padding-bottom: var(--dl-space-space-threeunits); */
    padding-bottom: 25px;
  }

  .home-caption3 {
    text-align: center;
    line-height: 32px;
  }

  .home-stat1 {
    gap: var(--dl-space-space-halfunit);
    width: 100%;
    /* padding-top: var(--dl-space-space-threeunits); */
    padding-top: 25px;
    /* padding-bottom: var(--dl-space-space-threeunits); */
    padding-bottom: 25px;
  }

  .home-caption4 {
    text-align: center;
    line-height: 32px;
  }

  .home-stat2 {
    gap: var(--dl-space-space-halfunit);
    width: 100%;
    /* padding-top: var(--dl-space-space-threeunits); */
    /* padding-bottom: var(--dl-space-space-threeunits); */
    border-left-width: 0px;
    padding-top: 25px;
    /* padding-bottom: var(--dl-space-space-threeunits); */
    padding-bottom: 25px;
  }

  .home-caption5 {
    text-align: center;
    line-height: 32px;
  }

  .home-section {
    gap: var(--dl-space-space-oneandhalfunits);
    padding-top: var(--dl-space-space-fourunits);
    flex-direction: column-reverse;
  }

  .home-content02 {
    gap: var(--dl-space-space-unit);
  }

  .home-explore1 {
    margin-top: var(--dl-space-space-halfunit);
  }

  .home-section1 {
    padding-top: var(--dl-space-space-fourunits);
    /* border-color: #DBDBDB; */
    flex-direction: column;
    /* border-top-width: 1px; */
  }

  .home-content03 {
    gap: var(--dl-space-space-unit);
  }

  .home-content04 {
    gap: var(--dl-space-space-twounits);
  }

  .home-section2 {
    align-items: center;
    padding-top: var(--dl-space-space-fourunits);
    border-color: #DBDBDB;
    flex-direction: column-reverse;
    padding-bottom: var(--dl-space-space-fourunits);
    border-top-width: 1px;
  }

  .home-content05 {
    margin-top: 0px;
  }

  .home-plans {
    padding-left: var(--dl-space-space-unit);
    padding-right: var(--dl-space-space-unit);
  }

  .home-overlay1 {
    width: 100%;
    background-size: contain;
    justify-content: space-between;
    background-repeat: no-repeat;
    background-position: right;
  }

  .home-header4 {
    gap: var(--dl-space-space-oneandhalfunits);
  }

  .home-heading08 {
    gap: var(--dl-space-space-halfunit);
    max-width: 600px;
  }

  .home-text35 {
    font-size: 18px;
  }

  .home-text36 {
    font-size: 20px;
    line-height: 26px;
  }

  .home-text37 {
    line-height: 21px;
  }

  .home-text38 {
    line-height: 21px;
  }

  .home-text39 {
    line-height: 21px;
  }

  .home-button3 {
    margin-top: var(--dl-space-space-threeunits);
  }

  .home-overlay2 {
    width: 100%;
    justify-content: space-between;
  }

  .home-header5 {
    gap: var(--dl-space-space-oneandhalfunits);
  }

  .home-heading12 {
    gap: var(--dl-space-space-halfunit);
    max-width: 600px;
  }

  .home-text57 {
    font-size: 18px;
  }

  .home-text58 {
    font-size: 20px;
    line-height: 26px;
  }

  .home-text59 {
    line-height: 21px;
  }

  .home-text60 {
    line-height: 21px;
  }

  .home-text61 {
    line-height: 21px;
  }

  .home-button7 {
    margin-top: var(--dl-space-space-threeunits);
  }

  .home-get-started1 {
    padding-top: var(--dl-space-space-fourunits);
    padding-bottom: var(--dl-space-space-fourunits);
  }

  .home-content09 {
    gap: var(--dl-space-space-oneandhalfunits);
  }

  .home-heading14 {
    gap: var(--dl-space-space-halfunit);
  }

  .home-text77 {
    font-size: 24px;
  }

  .home-text78 {
    font-size: 18px;
  }
}

@media(max-width: 767px) {
  .home-title {
    font-size: 28px;
    line-height: 32px;
  }

  .home-caption {
    font-size: 16px;
    line-height: 24px;
  }

  .home-images {
    bottom: -250px;
  }

  .home-text {
    width: 100%;
    font-size: 32px;
    max-width: 640px;
  }

  .home-video-container {
    gap: var(--dl-space-space-threeunits);
  }

  .home-heading01 {
    gap: var(--dl-space-space-halfunit);
  }

  .home-text01 {
    font-size: 20px;
    line-height: 30px;
  }

  .home-text02 {
    width: 100%;
    max-width: 600px;
  }

  .home-caption3 {
    font-size: 28px;
  }

  .home-description {
    font-size: 16px;
    line-height: 24px;
  }

  .home-caption4 {
    font-size: 28px;
  }

  .home-description1 {
    font-size: 16px;
    line-height: 24px;
  }

  .home-caption5 {
    font-size: 28px;
  }

  .home-description2 {
    font-size: 16px;
    line-height: 24px;
  }

  .home-text07 {
    font-size: 32px;
    line-height: 44px;
  }

  .home-text10 {
    font-size: 16px;
    line-height: 24px;
  }

  .home-get-started {
    display: none;
  }

  .home-section2 {
    gap: var(--dl-space-space-twounits);
  }

  .home-overlay {
    gap: var(--dl-space-space-oneandhalfunits);
    flex: 1;
    z-index: 50;
    padding-top: var(--dl-space-space-threeunits);
    padding-left: var(--dl-space-space-oneandhalfunits);
    padding-right: var(--dl-space-space-oneandhalfunits);
    flex-direction: column;
    padding-bottom: var(--dl-space-space-threeunits);
    justify-content: flex-start;
    background-image: none;
  }

  .home-text14 {
    font-size: 32px;
  }

  .home-pasted-image5 {
    right: 0px;
    bottom: 0px;
    height: 300px;
    display: flex;
    position: absolute;
    align-self: flex-end;
  }

  .home-features {
    gap: var(--dl-space-space-threeunits);
    margin-bottom: 30px;
    padding-bottom: 30px;
    margin-top: 30px;
  }

  .home-header2 {
    gap: var(--dl-space-space-unit);
  }

  .home-text16 {
    font-size: 14px;
  }

  .home-text17 {
    font-size: 32px;
    line-height: 44px;
  }

  .home-text18 {
    font-size: 16px;
    line-height: 24px;
  }

  .home-feature-list {
    gap: var(--dl-space-space-twounits);
    width: 100%;
    flex-direction: column;
  }

  .home-plans1 {
    gap: var(--dl-space-space-oneandhalfunits);
    display: flex;
  }

  .home-plan3 {
    max-width: 100%;
    padding-top: var(--dl-space-space-threeunits);
    border-width: 1px;
    padding-left: var(--dl-space-space-oneandhalfunits);
    border-radius: 16px;
    padding-right: var(--dl-space-space-oneandhalfunits);
    padding-bottom: var(--dl-space-space-threeunits);
    background-color: #ffffff;
  }

  .home-top3 {
    width: 100%;
    padding-bottom: var(--dl-space-space-oneandhalfunits);
  }

  .home-heading09 {
    gap: var(--dl-space-space-halfunit);
  }

  .home-text43 {
    font-size: 18px;
  }

  .home-text45 {
    font-size: 32px;
    line-height: 32px;
  }

  .home-plan4 {
    max-width: 100%;
    padding-top: var(--dl-space-space-threeunits);
    border-width: 1px;
    padding-left: var(--dl-space-space-oneandhalfunits);
    border-radius: 16px;
    padding-right: var(--dl-space-space-oneandhalfunits);
    padding-bottom: var(--dl-space-space-threeunits);
    background-color: #ffffff;
    border-right-width: 1px;
  }

  .home-top4 {
    width: 100%;
    padding-bottom: var(--dl-space-space-oneandhalfunits);
  }

  .home-heading10 {
    gap: var(--dl-space-space-halfunit);
  }

  .home-text47 {
    font-size: 18px;
  }

  .home-text49 {
    font-size: 32px;
    line-height: 32px;
  }

  .home-text51 {
    font-weight: 500;
  }

  .home-plan5 {
    max-width: 100%;
    padding-top: var(--dl-space-space-threeunits);
    border-width: 1px;
    padding-left: var(--dl-space-space-oneandhalfunits);
    border-radius: 16px;
    padding-right: var(--dl-space-space-oneandhalfunits);
    padding-bottom: var(--dl-space-space-threeunits);
    background-color: #ffffff;
    border-right-width: 1px;
  }

  .home-top5 {
    width: 100%;
    padding-bottom: var(--dl-space-space-oneandhalfunits);
  }

  .home-heading11 {
    gap: var(--dl-space-space-halfunit);
  }

  .home-text52 {
    font-size: 18px;
    font-style: normal;
    font-family: Poppins;
    font-weight: 600;
  }

  .home-text54 {
    font-size: 32px;
    font-style: normal;
    font-family: Poppins;
    font-weight: 600;
    line-height: 32px;
  }

  .home-text56 {
    font-weight: 500;
  }

  .home-expand1 {
    flex: 0 0 auto;
    width: 100%;
    border-radius: 16px;
    background-color: #375EE0;
  }

  .home-overlay2 {
    align-items: center;
    background-size: contain;
    justify-content: center;
    background-repeat: no-repeat;
    background-position: top right;
  }

  .home-logo-container {
    padding: 12px;
    margin-bottom: var(--dl-space-space-oneandhalfunits);
  }

  .home-logo {
    width: 16px;
    height: 16px;
  }

  .home-left {
    display: flex;
  }

  .home-right {
    display: flex;
  }

  .home-text67 {
    font-size: 14px;
  }

  .home-title1 {
    font-size: 16px;
  }

  .home-banner {
    height: unset;
    max-height: unset;
  }

  .home-banner .title {
    font-size: 43px;
    font-weight: 500;
    line-height: 50px;
    letter-spacing: -0.02em;
  }

  .home-banner .sub-title {
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
  }

  .banner-content-row {
    display: flex;
    flex-direction: column-reverse;
  }

  .home-banner .banner-image {
    margin: 40px 0;
    height: 328px;
    background-size: contain;
    background-position: center;
  }

  .banner-button {
    flex-direction: column;
    padding-bottom: 15px;
  }

  .home-page-stats {
    padding: 15px 0;
    gap: 75px;
  }

  .home-page-stats .col-md {
    text-align: center !important;
  }

  .home-section-2 .card-title {
    font-size: 24px;
    font-weight: 600;
    line-height: 32px;
    letter-spacing: -0.01em;
  }

  .home-section-3 .banner-content-row {
    padding: 60px 0 0 0;
    flex-direction: column;
  }

  .home-section-3.home-banner .banner-image {
    margin: 40px 0 0 0;
    height: 500px;
    background-size: 200%;
    background-position: 75%;
  }

  .section-title {
    width: unset;
    z-index: 1;
  }

  .testimonial-card-bg {
    position: absolute;
    right: 100px !important;
    zoom: 0.5;
    top: 150px;
    z-index: 0;
  }

  .testimonials .card {
    padding: 15px !important;
  }

  .testimonials .card-body {
    padding: 0 !important;
    padding-bottom: 325px !important;
  }

  .testimonials .card-img {
    bottom: 0;
    top: unset !important;
    height: 325px !important;
    left: 0;
    right: 0 !important;
    margin: auto;
  }

  .section-title.gradient-text::before {
    display: none;
  }

  .footer-section-1 {
    overflow: hidden;
  }

  .footer-footer .container {
    padding: 0;
  }

  .footer-footer .home-banner .banner-image {
    margin: 0;
    height: 430px;
    background-size: 200%;
    background-position: 75%;
  }
}

@media(max-width: 479px) {
  .home-container .home-header {
    padding-bottom: 80px !important;
  }

  .home-hero-buttons {
    width: 100%;
    flex-direction: column;
  }

  .home-ios-btn {
    width: 100%;
  }

  .home-android-btn {
    width: 100%;
  }

  .home-images {
    bottom: -300px;
  }

  .home-pasted-image {
    width: 150px;
  }

  .home-pasted-image1 {
    width: 150px;
  }

  .home-pasted-image2 {
    width: 150px;
  }

  .home-pasted-image3 {
    width: 150px;
  }

  .home-pasted-image4 {
    width: 150px;
  }

  .home-image {
    width: 100%;
    max-width: 400px;
  }

  .home-image2 {
    width: 100%;
    max-width: 400px;
  }

  .home-pasted-image5 {
    height: 225px;
  }

  .home-help {
    align-items: center;
    padding-top: var(--dl-space-space-twounits);
    flex-direction: column;
  }

  .home-buttons {
    margin-top: -40px;
  }

  .home-left {
    padding: var(--dl-space-space-halfunit);
  }

  .home-right {
    padding: var(--dl-space-space-halfunit);
  }

  .home-hero-buttons1 {
    width: 100%;
    flex-direction: column;
  }

  .home-ios-btn1 {
    /* width: 100%; */
  }

  .home-android-btn1 {
    /* width: 100%; */
  }
}


.top-trades-banner {
  background-image: url('../../public/trade-banner.svg');
  /* Path to your image */
  border-radius: 15px;
  gap: 7px;
  width: 100%;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  text-align: center;
  padding: 0 0 0 30px;
  color: #fff;
  height: 30vh;
}

.top-trades-banner span {
  font-family: 'DM Sans';
  font-style: normal;
  font-weight: 500;
  font-size: 20.8422px;
  line-height: 28px;
  text-align: center;
  letter-spacing: -1.1579px;
  color: #293040;
}

.top-trades-banner h1 {
  font-family: 'Jockey One';
  font-style: normal;
  font-weight: 400;
  font-size: 50.9477px;
  line-height: 56px;
  text-align: center;
  text-transform: uppercase;
  color: #222838;
}

.top-trades-banner .subscribe-btn {
  display: flex;
  flex-direction: row;
  justify-content: center;
  padding: 14px 20px;
  background: #293040;
  border-radius: 9.26321px;
  font-style: normal;
  font-weight: 500;
  display: flex;
  align-items: center;
  text-align: center;
  letter-spacing: 0.01em;
  color: #FFFFFF;
}

@media (max-width: 768px) {
  .top-trades-banner {
    width: 100%;
    background-size: cover;
    height: 160px;
    padding: 0px 0 0 10px;
    gap: 10px;
  }

  .top-trades-banner h1 {
    line-height: 0;
    font-size: 20px;
  }

  .top-trades-banner span {
    font-size: 14px;
  }

  .channel_table h2 {
    font-size: 18px;
  }

}

.legal_container h2 {
  margin-top: 15px;
}

.home-textNew {
  font-style: normal;
  font-weight: 500;
  font-size: 15px;
}