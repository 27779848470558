/* Add this to your CSS file */
/*
.input-group {
    margin-bottom: 1rem;
  }
  
  .input-group label {
    display: block;
    margin-bottom: .5rem;
  }
  
  .input-group input,
  .input-group textarea {
    width: 100%;
    padding: .5rem;
    margin-bottom: .5rem;
    border: 1px solid #ccc;
    border-radius: 4px;
  }
  
  .input-group .error {
    color: red;
    font-size: .8rem;
  }
  
  button {
    background-color: #e53e3e;
    color: white;
    border: none;
    border-radius: 4px;
    padding: .5rem 1rem;
    cursor: pointer;
  }
  
  .input-error {
    border-color: red;
  }
  */

/* Define your variables as CSS custom properties */
:root {
  --teal: #00b4cf;
  --white: #ffffff;
}

.contact_us .error {
  color: red;
  font-size: 14px;
  margin-top: -5px;
}

.input-error {
  border-color: red;
}

.contact_us .container {
  background-color: var(--white);
  /* border-radius: 5px; */
  box-shadow: 0 14px 28px rgba(0, 0, 0, 0.25), 0 10px 10px rgba(0, 0, 0, 0.22);
  position: relative;
  overflow: hidden;
  /* width: 768px; */
  max-width: 100%;
  min-height: 480px;
  min-width: 370px;
  width: 100%;
  /* margin-top: 45px; */
}

.contact_us h2 {
  font-size: 40px;
  margin-bottom: 1rem;
  text-align: left;
}

.contact_us .form-container {
  display: flex;
}

.right-container {
  padding: 32px;
  display: flex;
  flex: 1.5 1;
  justify-content: center;
  align-items: center;
}

.left-container {
  flex: 2 1;
}

.left-container p {
  font-size: 18px;
  text-align: left;
  margin-top: 30px;
  /* line-height: 26px; */
  font-weight: 500;
}

.right-inner-container {
  width: 100%;
}

.left-inner-container {
  width: 80%;
  color: #C2BFBF;
  font-size: 20px;
  font-weight: 400;
  line-height: 36px;
}

.contact_us input,
.contact_us textarea {
  width: 100%;
  margin: 2px 0 12px 0;
  padding: 17px 12px 17px 16px;
  color: #8897AD;
  background: #F7FBFF;
  border: 1px solid #D4D7E3;
  border-radius: 8px;
  font-size: 16px;
  font-weight: 400;
  line-height: 16px;
  letter-spacing: 0.01em;
}

.contact_us input:focus,
.contact_us textarea:focus {
  outline: 1px solid var(--teal);
}

.copy {
  color: #0BE096;
  font-size: 18px;
  font-weight: 400;
  line-height: 19px;
  text-decoration: none;
  cursor: pointer;
}

@media(max-width : 991px) {
  .left-inner-container {
    width: 100%;
  }

  .right-container {
    margin-top: 40px;
  }
}

@media only screen and (max-width: 767px) {
  .right-inner-container {
    height: auto;
  }

  .contact_us h2 {
    font-size: 28px;
  }

  .left-container p {
    font-size: 16px;
    margin-top: 0;
  }

  .contact_us .form-container {
    display: grid;
  }
}

form p {
  text-align: left;
}