.point-point {
  gap: var(--dl-space-space-halfunit);
  display: flex;
  position: relative;
  align-items: center;
  flex-direction: row;
  justify-content: center;
}
.point-check {
  display: flex;
  padding: var(--dl-space-space-halfunit);
  align-items: flex-start;
  border-radius: var(--dl-radius-radius-round);
  flex-direction: column;
  justify-content: center;
  background-color: var(--dl-color-correct);
}
.point-icon {
  width: 12px;
  height: 12px;
}
.point-text {
  max-width: 535px;
  font-style: normal;
  font-family: Poppins;
  font-weight: 600;
  line-height: 24px;
}


@media(max-width: 991px) {
  .point-text {
    font-size: 14px;
  }

  .home-image-register{
    display: none !important;
  }

}