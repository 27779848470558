.our_story {
    background-image: url("../../public/about-us-banner-bg.svg");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: bottom;
}

.our-story-text {
    color: #C2BFBF;
    font-size: 20px;
    font-weight: 400;
    line-height: 38px;
}

.join-us {
    margin-top: 100px;
    padding: 80px;
    border-radius: 16px;
}

.join-us .sub-title {
    color: #C2BFBF;
    font-size: 24px;
    font-weight: 500;
    line-height: 36px;
}

@media (max-width: 991px) {
    .our_story {
        height: calc(100vh - 241px);
        background-image: url("../../public/about-us-banner-bg-mobile.svg");
    }

    .our-story-text {
        font-size: 16px;
        font-weight: 400;
        line-height: 34px;
    }

    .join-us {
        margin-top: 40px;
        padding: 16px;
    }

    .join-us .sub-title {
        margin: 25px 0;
        font-size: 15px;
        font-weight: 500;
        line-height: 28px;
    }
}