.footer-footer {
  gap: var(--dl-space-space-threeunits);
  width: 100%;
  display: flex;
  position: relative;
  align-items: center;
  flex-direction: column;
  padding-top: var(--dl-space-space-sixunits);
  padding-left: var(--dl-space-space-fiveunits);
  padding-right: var(--dl-space-space-fiveunits);
  padding-bottom: 0;
}

.footer-content {
  padding-bottom: 15px;
  display: flex;
  align-items: flex-start;
  flex-direction: row;
  justify-content: space-between;
}

.footer-information {
  gap: var(--dl-space-space-unit);
  height: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}

.footer-heading {
  gap: var(--dl-space-space-unit);
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  max-width: 375px;
  align-items: flex-start;
  flex-direction: column;
  justify-content: flex-start;
}

.footer-pasted-image {
  width: 150px;
}

.footer-text {
  color: #011323;
  font-size: 14px;
  font-weight: 400;
  line-height: 24px;
}

.footer-socials {
  display: flex;
  gap: 30px;
}

.footer-socials a {
  color: #8F8F8F;
  transition: 0.3s;
}

.footer-socials a:hover {
  color: #333;
}

.footer-links {
  gap: 300px;
  display: flex;
  z-index: 15;
  align-items: flex-start;
  flex-direction: row;
  justify-content: center;
}

.footer-column {
  gap: 10px;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: center;
}

.footer-header {
  color: #98A2B3;
  font-size: 14px;
  font-weight: 600;
  line-height: 18px;
}

.footer-link {
  color: #C4C4C4;
  cursor: pointer;
  font-size: 14px;
  transition: 0.3s;
  line-height: 21px;
}

.footer-link:hover {
  color: rgba(196, 196, 196, 0.5);
}

.footer-link03 {
  color: rgb(196, 196, 196);
  cursor: pointer;
  font-size: 14px;
  transition: 0.3s;
  line-height: 21px;
}

.footer-link03:hover {
  color: rgba(196, 196, 196, 0.5);
}

.footer-link04 {
  color: rgb(196, 196, 196);
  cursor: pointer;
  font-size: 14px;
  transition: 0.3s;
  line-height: 21px;
}

.footer-link04:hover {
  color: rgba(196, 196, 196, 0.5);
}

.footer-column1 {
  gap: 16.2px;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: center;
}

.footer-link05 a {
  color: #4D4D4D;
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  text-decoration: none;
  transition: 0.3s;
}

.footer-link05 a:hover {
  color: #000;
}

.footer-link06 {
  color: rgb(196, 196, 196);
  cursor: pointer;
  font-size: 14px;
  transition: 0.3s;
  line-height: 21px;
}

.footer-link06:hover {
  color: rgba(196, 196, 196, 0.5);
}

.footer-link07 {
  color: rgb(196, 196, 196);
  cursor: pointer;
  font-size: 14px;
  transition: 0.3s;
  line-height: 21px;
}

.footer-link07:hover {
  color: rgba(196, 196, 196, 0.5);
}

.footer-link08 {
  color: rgb(196, 196, 196);
  cursor: pointer;
  font-size: 14px;
  transition: 0.3s;
  line-height: 21px;
}

.footer-link08:hover {
  color: rgba(196, 196, 196, 0.5);
}

.footer-link09 {
  color: rgb(196, 196, 196);
  cursor: pointer;
  font-size: 14px;
  transition: 0.3s;
  line-height: 21px;
}

.footer-link09:hover {
  color: rgba(196, 196, 196, 0.5);
}

.footer-link10 {
  color: rgb(196, 196, 196);
  cursor: pointer;
  font-size: 14px;
  transition: 0.3s;
  line-height: 21px;
}

.footer-link10:hover {
  color: rgba(196, 196, 196, 0.5);
}

.footer-text1 {
  color: #011323;
  font-size: 14px;
  font-weight: 400;
  line-height: 24px;
}

.footer-links1 {
  padding: 0 0 15px 0px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.footer-section-1 {
  height: max-content;
  margin-bottom: 100px;
  background-color: #3477F1;
  border-radius: 16px;
}

.footer-section-1 .title {
  position: relative;
  color: #fff;
  font-size: 36px;
  font-weight: 800;
  line-height: 52px;
}

.footer-section-1 .title svg {
  position: absolute;
  top: -70px;
  right: -60px;
}

.footer-section-1 .sub-title {
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  color: #F2F4F7;
}

.footer-section-1 .banner-content {
  padding-left: 100px;
}

.footer-section-1 .banner-image {
  height: 445px;
  background-image: url(./../../public/copy-trading.png);
  background-size: 110%;
  background-position: 140% bottom;
}

@media(max-width: 1300px) {
  .footer-links {
    gap: 150px;
  }
}

@media(max-width: 991px) {
  .footer-section-1 .banner-content {
    padding: 84px 40px 40px 40px;
  }

  .footer-section-1 .title {
    font-size: 30px;
    font-weight: 700;
  }

  .footer-section-1 .title svg {
    right: -15px;
  }

  .footer-section-1 .banner-image {
    height: 350px;
    background-size: 150%;
    background-position: right bottom;
  }

  .footer-links {
    gap: 50px;
  }

  .footer-footer {
    padding-top: 20px;
    padding-left: 20px;
    padding-right: 20px;
  }
}

@media(max-width: 767px) {
  .footer-footer {
    gap: var(--dl-space-space-twounits);
  }

  .footer-content {
    gap: var(--dl-space-space-threeunits);
    flex-direction: column;
    justify-content: flex-start;
  }

  .footer-information {
    gap: var(--dl-space-space-oneandhalfunits);
  }

  .footer-heading {
    gap: var(--dl-space-space-halfunit);
  }

  .footer-pasted-image {
    width: 100px;
  }

  .footer-links {
    gap: var(--dl-space-space-oneandhalfunits);
    width: 100%;
    flex-direction: column;
  }

  .footer-links1 {
    flex-direction: column;
    gap: 15px;
  }

  .footer-header {
    font-size: 16px;
    line-height: 24px;
  }

  .footer-header1 {
    font-size: 16px;
    line-height: 24px;
  }

  .banner-button a {
    width: 100%;
  }

  .banner-button button {
    width: 100%;
  }
}

@media(max-width: 479px) {
  .footer-links {
    width: 100%;
    flex-direction: column;
  }
}