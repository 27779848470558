.product .home-content{
    padding-top: 50px !important;
    padding-bottom: 0 !important;
}
.product .home-section{
    padding-top: 0;
}
.product .highlight-text1{
    max-width: 100%;
    text-align:center;
}

.product .home-section1:nth-child(3){
    padding-bottom: var(--dl-space-space-sixunits);
    
}
.selection {
    width: 100%;
    display: flex;
    align-items: center;
    flex-direction: row;
    justify-content: center;
  }
.text057 {
    color: #EEE9FE;
    width: 128px;
    font-style: normal;
    text-align: center;
    font-weight: 600;
    padding-top: var(--dl-space-space-oneandhalfunits);
    border-color: rgba(255, 255, 255, 0.3);
    padding-bottom: var(--dl-space-space-oneandhalfunits);
    border-bottom-width: 2px;
  }
  .text058 {
    color: #ffffff;
    width: 128px;
    font-style: normal;
    text-align: center;
    font-weight: 600;
    padding-top: var(--dl-space-space-oneandhalfunits);
    border-color: #ffffff;
    padding-bottom: var(--dl-space-space-oneandhalfunits);
    border-bottom-width: 2px;
  }
  .text057:hover,.text058:hover{
      cursor: pointer !important;
  }
  
@media (max-width: 767px){
    .product .home-title{
        text-align: center
    }
.product .highlight-text {
    text-align: left;
}
}