.primaryButton {
  padding: 20px 45px;
  border-radius: 45px;
  /* Adjust the border-radius as needed */
  border: none;
  font-weight: bold;
  background-color: #0be096;
  /* Replace with the exact color from your design */
  color: white;
  font-size: 16px;
  /* Adjust the font-size as needed */
  cursor: pointer;
  margin: 0 10px;
  /* Provides spacing between buttons */
}

.secondaryButton {
  font-weight: bold;
  padding: 20px 45px;
  border-radius: 45px;
  /* Adjust the border-radius as needed */
  border: none;
  background-color: #656D78;
  /* Replace with the exact color from your design */
  color: white;
  font-size: 16px;
  /* Adjust the font-size as needed */
  cursor: pointer;
  margin: 0 10px;
  /* Provides spacing between buttons */
}

.cards_custom .container {
  background-color: #fff;
  /* Replace with actual background color */
  text-align: center;
  padding: 30px 20px;
}

.cards_custom .heading {
  margin: 0 0 20px;
  font-size: 30px;
  font-weight: bold;
  color: #0be096;
}

.cards_custom .cardContainer {
  width: 70%;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin: 0 auto;
}

.cards_custom_register .cardContainer {
  width: 90%;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin: 0 auto;
}

.cards_custom .card {
  padding: 40px;
  margin: 10px;
  flex-basis: calc(33% - 20px);
  /* 25% width for each card, minus margin */
  border: 1px solid #e0e0e0;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  text-align: center;
}

.cards_custom_register .card {
  padding: 20px;
  margin: 10px;
  flex-basis: calc(25% - 20px);
  /* 25% width for each card, minus margin */
  border: 1px solid #e0e0e0;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  text-align: center;
}

.cards_custom .icon {
  font-size: 24px;
  padding: 16px;
  background-color: #dfe7da;
  border-radius: 50%;
  margin: 0 auto;
}


/* pricing starts here*/

.pricing_cards .cardHeader {
  margin-bottom: 30px;
  min-height: 60px;
  color: #767676;
  font-size: 24px;
  font-weight: 500;
  line-height: 29.05px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.pricing_cards .cardHeader .badge {
  position: absolute;
  top: -15px;
  left: 0;
  right: 0;
  width: max-content;
  margin: auto;
  color: #011323;
  background-image: linear-gradient(270deg, #1F86DB 0%, #0BE096 100%) !important;
  font-size: 14px;
  font-weight: 400;
  line-height: 18.23px;
}

.pricing_cards .container {
  padding: 0;
  justify-content: center;
}

.pricing_cards .card-container {
  height: 100%;
}

.pricing_cards .card {
  position: relative;
  padding: 20px;
  width: 100%;
  height: 100%;
  color: white;
  background-color: #1C1C1C;
  border: 1px solid #2B2B2B;
  border-radius: 12px;
  display: flex;
  justify-content: space-between;
}

.pricing_cards .price {
  margin: 0;
  font-size: 50px;
  font-weight: 500;
  line-height: 65.63px;
}

.pricing_cards .price span {
  color: #767676;
  font-size: 16px;
  font-weight: 400;
  line-height: 18.75px;
}

.pricing_cards .featuresList {
  margin-top: 30px;
  list-style: none;
  padding-left: 0;
  margin-bottom: 20px;
}

.pricing_cards .feature {
  margin-bottom: 15px;
  padding-bottom: 12px;
  font-size: 16px;
  font-weight: 400;
  line-height: 20px;
  border-bottom: 1px solid #292929;
}

.pricing_cards .feature:last-child {
  border: 0;
}

.testimonials .card {
  position: relative;
  padding: 75px;
  background-color: #F4F4F4;
  border-radius: 24px;
  border: 1px solid #CCCFE0;
  box-shadow: 0px 2px 8px 0px #F1F6FF;
}

.testimonials .card-img {
  position: absolute;
  top: 0;
  right: 75px;
  width: auto;
  height: 100%;
}

.testimonials .card-body {
  padding-right: 325px;
  border: 0;
}

.testimonials .card-text {
  margin-bottom: 60px;
  color: #4D4D4D;
  font-size: 20px;
  font-weight: 300;
  line-height: 32px;
}

.testimonials .card-title {
  color: #011323;
  font-size: 28px;
  font-weight: 600;
  line-height: 36px;
  letter-spacing: -0.5px;
}

.testimonials .badge {
  color: #FFFFFF;
  background-image: linear-gradient(270deg, #1F86DB 0%, #0BE096 100%) !important;
  font-weight: 500;
  line-height: 18px;
  letter-spacing: -0.5px;
}

.testimonials .carousel-indicators {
  bottom: -60px;
}

.testimonials .carousel-indicators button {
  width: 8px;
  height: 8px;
  background-color: #969696 !important;
  border: 4px solid transparent;
  border-radius: 50%;
  transition: 0.3s;
}

.testimonials .carousel-indicators button.active {
  background-color: #3477F1 !important;
  border-color: #3477F1 !important;
}

.testimonials .carousel-inner {
  overflow: visible;
}

.testimonial-card-bg {
  position: absolute;
  right: 12px;
}

.testimonial-card-bg-1 {
  position: absolute;
  right: 30px;
  top: -90px;
}

.testimonial-card-bg-2 {
  position: absolute;
  right: -55px;
  top: 50px;
}

.testimonial-card-bg-3 {
  position: absolute;
  right: -60px;
  top: -40px;
}

.social-sticky {
  position: absolute;
  left: -45px;
  width: max-content;
  top: 180px;
  z-index: 9;
  background: #FAFAFA;
  gap: 14px;
  padding: 8px 5px;
  border-radius: 6px;
  /* border: 1px solid #E3E3E3; */
  display: flex;
  align-items: center;
  flex-direction: column;
}

.page-title {
  position: relative;
  padding-left: 15px;
  color: #fff;
  font-size: 40px;
  font-weight: 600;
  line-height: 52px;
}

.page-title::before {
  content: "";
  position: absolute;
  left: 0;
  width: 3px;
  height: 100%;
  background-color: #0BE096;
  border-radius: 24px;
}

.page-subtitle {
  margin-top: 20px;
  width: max-content;
  max-width: 100%;
  font-size: 16px;
  font-weight: 500;
  line-height: 20px;
}

.page-padding {
  padding: 60px 0;
}

.our-trade-page .nav-underline {
  width: max-content;
  margin: auto;
}

.channel-select .form-select {
  padding: 14px 40px 14px 30px;
  color: #fff;
  background-color: transparent;
  border-radius: 46px;
  border: 1px solid #464646;
  font-size: 18px;
  font-weight: 500;
  line-height: 24px;
  text-align: center;
}

.no-icon::before {
  background-image: none !important;
}

.still-question {
  background-color: #000;
}

@media(max-width: 991px) {

  .cards_custom_register .home-get-started {
    display: block !important;
  }

  .cards_custom .cardContainer {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    /* margin: 0 auto; */
  }
}

@media (max-width: 767px) {

  .pricing_cards .container {
    flex-direction: column;
  }

  .cards_custom .cardContainer {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    /* margin: 0 auto; */
  }

  .cards_custom .card {
    flex-basis: calc(50% - 20px);
    /* 25% width for each card, minus margin */
  }



  .primaryButton,
  .secondaryButton {
    padding: 10px 25px;
    font-size: 14px;

  }
}

@media(max-width: 479px) {
  .cards_custom .card {
    flex-basis: calc(100% - 20px);
    /* 25% width for each card, minus margin */
  }
}



.old-price {
  font-size: 1.2em;
  color: #999;
  /* Optional: Color of the old price */
  text-decoration: line-through;
  /* Strikethrough */
  margin: 10px 0;
}


/* Rohit css */



@import url('https://fonts.googleapis.com/css2?family=Mulish:wght@200;300;400;500;600;700;800;900&display=swap');

body {
  margin: 0;
  padding: 0;
  font-family: "Inter", sans-serif !important;
  font-size: 14px;
  color: #3a3a3a;
  font-weight: 400;
  position: relative;
}

* {
  margin: 0px;
  padding: 0px;
}

.product-page {
  background-color: #151717;
  font-family: Inter;
  position: relative;
  overflow: hidden;
}

.product-page:before {
  content: '';
  background-image: url('../../assets/price_top.png');
  position: absolute;
  width: 100%;
  height: 300px;
  background-size: cover;
  background-position: 0 -50px;
  top: 0;
}

.product-page:after {
  content: '';
  background-image: url('../../assets/price_bottom.png');
  position: absolute;
  width: 100%;
  height: 500px;
  background-size: cover;
  background-position: 0 -140px;
  bottom: 0;
}

.product-page .MembershipHead {
  margin: 0 0 40px 0;
  padding: 0 50px;
  position: relative;
  z-index: 1;
}

.product-page .MembershipHead h6 {
  font-size: 36px;
  color: #fff;
  margin: 0 0 20px 0;
  font-weight: 700;
  padding: 0 0 0 15px;
  position: relative;
}

.product-page .MembershipHead h6:before {
  content: '';
  position: absolute;
  left: 0;
  width: 3px;
  height: 100%;
  background: #0BE096;
  border-radius: 10px;
}

.product-page .MembershipHead p {
  font-size: 16px;
  font-weight: 600;
  background: linear-gradient(90deg, #26E098 0%, #3477F1 100%);
  background-color: transparent;
  -o-background-clip: text;
  -ms-background-clip: text;
  -moz-background-clip: text;
  -webkit-background-clip: text;
  -o-text-fill-color: transparent;
  -ms-text-fill-color: transparent;
  -moz-text-fill-color: transparent;
  -webkit-text-fill-color: transparent;
  outline: 0;
  border: none;
  display: inline-block;
  margin: 0;
}

.product-page .MembershipBody {
  padding: 0 10px;
  margin: 0 0 30px 0;
  position: relative;
  z-index: 1;
}

.product-page .MembershipBody .MembershipTitle {
  display: flex;
  margin: 0 -5px
}

.product-page .MembershipBody .MembershipTitle article:first-child {
  width: calc(100% - 660px);
  padding: 15px 45px 15px 15px
}

.product-page .MembershipBody .MembershipTitle article {
  border: 1px solid #979797;
  background: #1C1C1C;
  width: 165px;
  margin: 0 5px;
  text-align: center;
  border-radius: 10px 10px 0 0;
  border-bottom: none;
  padding: 15px 10px
}

.product-page .MembershipBody .MembershipTitle article p {
  color: #fff;
  font-weight: 600;
  text-transform: uppercase;
  font-size: 14px;
  padding: 0 8px;
  margin: 0;
}

.product-page .MembershipBody .MembershipPlan {
  display: flex;
  margin: 0 -5px
}

.product-page .MembershipBody .MembershipPlan article:first-child {
  width: calc(100% - 660px);
  padding: 15px 45px 15px 15px
}

.product-page .MembershipBody .MembershipPlan article {
  border-right: 1px solid #979797;
  border-left: 1px solid #979797;
  background: #1C1C1C;
  width: 165px;
  margin: 0 5px;
  text-align: center;
  border-radius: 0;
  border-bottom: none;
  padding: 15px 10px;
  position: relative;
}

.product-page .MembershipBody .MembershipPlan article p {
  color: #fff;
  font-weight: 600;
  text-transform: capitalize;
  font-size: 14px;
  text-align: right;
  margin: 0;
}

.product-page .MembershipBody .MembershipPlan article span {
  display: inline-block;
  max-width: 20px;
}

.product-page .MembershipBody .MembershipPlan article span img {
  width: 100%
}

.product-page .MembershipBody .MembershipPlan article button {
  background: #26E098;
  border: none;
  padding: 10px 20px;
  font-weight: 600;
  border-radius: 5px;
  font-size: 13px
}

.product-page .MembershipBody .MembershipPlan:last-child article {
  border: 1px solid #979797;
  background: #1c1c1c;
  text-align: center;
  border-radius: 0 0 10px 10px;
  border-top: none;
  padding-bottom: 25px
}

.product-page .MembershipBody .MembershipPlan.MembershipPrice article:before {
  content: '';
  background-color: #C3FFE8;
  position: absolute;
  top: 0;
  left: -10px;
  width: calc(100% + 25px);
  height: 100%;
  z-index: 1
}

.product-page .MembershipBody .MembershipPlan.MembershipPrice article:last-child:before {
  width: calc(100% + 21px);
}

.product-page .MembershipBody .MembershipPlan.MembershipPrice article p {
  position: relative;
  z-index: 2;
  font-size: 16px;
  padding: 6px 0 0 0;
  color: #000;
  margin: 0;
}

.product-page .MembershipBody .MembershipPlan.MembershipPrice article span {
  position: relative;
  z-index: 2;
  color: #333;
  font-size: 22px;
  max-width: inherit;
  font-weight: 600
}

.product-page .MembershipFooter {
  min-height: 270px;
  padding: 0 10px;
  position: relative;
  z-index: 1;
}

.product-page .MembershipFooter .Description {
  border: 1px solid #979797;
  background: #1c1c1c;
  padding: 50px 50px;
  border-radius: 10px;
  margin: 0 0
}

.product-page .MembershipFooter .Description h6 {
  text-align: center;
  margin: 0 0 25px 0
}

.product-page .MembershipFooter .Description h6 span {
  font-size: 36px;
  border-bottom: 2px solid #ffffff;
  font-weight: 700;
  color: #fff;
  padding: 0 0 7px 0;
  display: inline-block;
}

.product-page .MembershipFooter .Description p {
  font-size: 20px;
  margin: 0;
  font-weight: 500;
  color: #fff;
  line-height: 27px;
}

.TradingArea {
  padding: 50px
}

.TradingArea .TradingBox {
  background: #3477f1;
  display: flex;
  padding: 50px 50px 0 50px;
  border-radius: 40px;
  justify-content: space-between;
  align-items: center;
  position: relative
}

.TradingArea .TradingBox .Star {
  position: absolute;
  top: 20px;
  width: 115px;
  left: 0;
  right: 0;
  margin: auto
}

.TradingArea .TradingBox .Star img {
  width: 100%
}

.TradingArea .TradingBox .TradingLeft h3 {
  color: #fff;
  font-size: 42px;
  font-weight: 800;
  margin: 0 0 30px 0;
  line-height: 53px
}

.TradingArea .TradingBox .TradingLeft p {
  color: #fff;
  font-size: 18px;
  line-height: 28px;
  margin: 0 0 40px 0
}

.TradingArea .TradingBox .TradingLeft a {
  background-color: #fff;
  color: #000;
  font-size: 15px;
  padding: 18px 45px;
  display: inline-block;
  border-radius: 10px;
  font-weight: 600;
  letter-spacing: .5px
}

.TradingArea .TradingBox .TradingRight {
  max-width: 450px
}

.TradingArea .TradingBox .TradingRight img {
  width: 100%
}

.Description {
  transition: opacity 0.3s ease;
  opacity: 0;
  visibility: hidden;
  display: none;
}

.Description.active {
  opacity: 1;
  visibility: visible;
  display: block;
}


/*New CSS Two Pages */

.CalculatorArea {
  border: 1px solid #ddd;
  border-radius: 10px;
  padding: 60px;
  position: relative;
  background-color: #1c201f;
  background-image: url('../../assets/calculator-bg.png');
  background-size: cover;
  z-index: 9
}

.CalculatorArea h3 {
  text-align: center;
  color: #fff;
  margin: 0 0 30px 0;
  font-size: 30px;
  font-weight: 600
}

.CalculatorArea .CalculatorBox {
  display: flex;
  align-items: flex-start;
}

.CalculatorArea .CalculatorBox .CalculatorLeft {
  width: 50%
}

.CalculatorArea .CalculatorBox .CalculatorLeft ul {
  display: flex;
  color: #fff;
  align-items: center;
  list-style: none;
  margin: 0 0 8px 0
}

.CalculatorArea .CalculatorBox .CalculatorLeft ul li {
  margin: 0 15px 0 0
}

.CalculatorArea .CalculatorBox .CalculatorLeft ul li:nth-child(1) {
  min-width: 200px
}

/* .CalculatorArea .CalculatorBox .CalculatorLeft ul li:nth-child(2) {
  min-width: 150px
} */

.CalculatorArea .CalculatorBox .CalculatorLeft ul li span {
  border: 1px solid #979797;
  display: block;
  padding: 8px 15px;
  border-radius: 10px
}

.CalculatorArea .CalculatorBox .CalculatorLeft ul li:nth-child(2) span {
  text-align: right
}

.CalculatorArea .CalculatorBox .CalculatorLeft ul li strong {
  font-size: 15px;
  font-weight: 500
}

.CalculatorArea .CalculatorBox .CalculatorLeft ul li a {
  display: block;
  width: 25px
}

.CalculatorArea .CalculatorBox .CalculatorLeft ul li a img {
  width: 100%
}

.CalculatorArea .CalculatorBox .CalculatorRight {
  display: flex;
  flex-wrap: wrap;
  width: 50%;
  align-items: center
}

.CalculatorArea .CalculatorBox .CalculatorRight aside {
  width: 50%;
  color: #fff;
  display: flex;
  flex-direction: column;
  padding: 0px 10px;
  min-height: 110px;
  align-items: center;
  margin: 0 0 45px 0;
}

.CalculatorArea .CalculatorBox .CalculatorRight aside label {
  display: flex;
  text-align: center;
  font-size: 18px;
  line-height: 22px;
  min-height: 68px;
  align-items: center;
}

.CalculatorArea .CalculatorBox .CalculatorRight aside span {
  border: 1px solid #979797;
  display: inline-block;
  padding: 5px 20px;
  border-radius: 10px;
  min-width: 170px;
  text-align: center;
  font-size: 20px;
  font-weight: 700;
  height: 42px;
}


.OptionsArea {
  background-color: #1c201f;
  background-image: url('../../assets/calculator-bg.png');
  background-size: cover;
  border: 1px solid #ddd;
  border-radius: 10px;
  padding: 60px;
  position: relative;
  z-index: 9
}

.OptionsArea h3 {
  text-align: center;
  color: #fff;
  margin: 0 0 10px 0;
  font-size: 30px;
  font-weight: 600
}

.OptionsArea p {
  text-align: center;
  color: #fff;
  margin: 0 0 40px 0;
  font-size: 18px;
  font-weight: 400;
}

.OptionsArea .OptionsBox {
  display: flex
}

.OptionsArea .OptionsBox .OptionsLeft {
  width: 60%;
}

.OptionsArea .OptionsBox .OptionsLeft ul {
  display: flex;
  color: #fff;
  align-items: center;
  list-style: none;
  margin: 0 0 20px 0
}

.OptionsArea .OptionsBox .OptionsLeft ul li {
  margin: 0 10px;
  width: 100%;
}

.OptionsArea .OptionsBox .OptionsLeft ul li:last-child {
  width: auto;
  display: flex;
  min-width: 60px;
  margin: 0 0 0 0;
}


.OptionsArea .OptionsBox .OptionsLeft ul li:last-child a+a {
  margin-left: 5px;
}

.OptionsArea .OptionsBox .OptionsLeft ul li span {
  border: 1px solid #979797;
  display: block;
  padding: 10px 20px;
  border-radius: 10px;
  text-align: center;
  min-height: 49px;
  font-size: 16px;
}

.OptionsArea .OptionsBox .OptionsLeft ul li:nth-child(2) span {
  text-align: right
}

.OptionsArea .OptionsBox .OptionsLeft ul li strong {
  font-size: 15px;
  font-weight: 500;
  display: block;
  text-align: center;
}

.OptionsArea .OptionsBox .OptionsLeft ul li a {
  display: block;
  width: 25px
}

.OptionsArea .OptionsBox .OptionsLeft ul li a img {
  width: 100%
}

.OptionsArea .OptionsBox .OptionsRight {
  width: 40%;
  padding: 100px 0 0 30px;
}

.OptionsArea .OptionsBox .OptionsRight .OptionsRisk {
  border: 1px solid #fff;
  border-top: 0;
  padding: 30px 20px 20px 20px;
  position: relative;
  margin: 0 0 60px 0
}

.OptionsArea .OptionsBox .OptionsRight .OptionsRisk h4 {
  text-align: center;
  position: absolute;
  top: -16px;
  left: 0;
  width: 100%
}

.OptionsArea .OptionsBox .OptionsRight .OptionsRisk h4 span {
  color: #fff;
  padding: 10px 30px;
  font-size: 20px;
  font-weight: 400
}

.OptionsArea .OptionsBox .OptionsRight .OptionsRisk h4 span::before {
  position: absolute;
  width: 95px;
  content: '';
  height: 1px;
  background: #fff;
  left: -1px;
  top: 16px;
}

.OptionsArea .OptionsBox .OptionsRight .OptionsRisk h4 span::after {
  position: absolute;
  width: 95px;
  content: '';
  height: 1px;
  background: #fff;
  right: -1px;
  top: 16px;
}

.OptionsArea .OptionsBox .OptionsRight .OptionsRisk:nth-child(2) h4 span::before,
.OptionsArea .OptionsBox .OptionsRight .OptionsRisk:nth-child(2) h4 span::after {
  width: 118px;
}

.OptionsArea .OptionsBox .OptionsRight .OptionsRisk:nth-child(3) h4 span::before,
.OptionsArea .OptionsBox .OptionsRight .OptionsRisk:nth-child(3) h4 span::after {
  width: 135px;
}

.OptionsArea .OptionsBox .OptionsRight .OptionsRisk:nth-child(4) h4 span::before,
.OptionsArea .OptionsBox .OptionsRight .OptionsRisk:nth-child(4) h4 span::after {
  width: 65px;
}


.OptionsArea .OptionsBox .OptionsRight .OptionsRisk article {
  display: flex
}

.OptionsArea .OptionsBox .OptionsRight .OptionsRisk article+article {
  margin-top: 40px
}

.OptionsArea .OptionsBox .OptionsRight .OptionsRisk article aside {
  width: 100%
}

.OptionsArea .OptionsBox .OptionsRight .OptionsRisk article aside h6 {
  font-size: 15px;
  font-weight: 500;
  display: block;
  text-align: center;
  color: #fff;
  margin: 0 0 10px 0;
  min-height: 36px;
  display: flex;
  align-items: center;
  justify-content: end;
  flex-direction: column;
}

.OptionsArea .OptionsBox .OptionsRight .OptionsRisk article aside h6 span {
  font-size: 12px;
}

.OptionsArea .OptionsBox .OptionsRight .OptionsRisk article aside p {
  margin: 0;
  border: 1px solid #979797;
  display: block;
  padding: 10px 20px;
  border-radius: 10px;
  text-align: center;
  font-size: 18px;
  min-height: 51px;
}

.calculator-title {
  font-size: 26px;
  color: #fff;
  position: relative;
}

.CalculatorArea .CalculatorBox .CalculatorLeft ul li input {
  border: 1px solid #979797;
  display: block;
  padding: 5px 15px;
  border-radius: 10px;
  background: transparent;
  color: #fff;
  font-size: 18px;
  text-align: right;
  width: 130px;
}

.CalculatorArea .CalculatorBox .CalculatorRight aside select {
  border: 1px solid #979797;
  display: inline-block;
  padding: 5px 20px;
  border-radius: 10px;
  min-width: 170px;
  text-align: center;
  font-size: 20px;
  font-weight: 700;
  background: transparent;
  color: #fff;
  appearance: none;
  background-image: url('../../assets/chevron-down.png');
  background-repeat: no-repeat;
  background-position: 94% 55%;
}


.CalculatorArea .CalculatorBox .CalculatorRight aside .Icon {}

.CalculatorArea .CalculatorBox .CalculatorRight aside select option {
  color: #000;
}

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type="number"] {
  -moz-appearance: textfield;
}

.OptionsArea .OptionsBox .OptionsLeft ul li input {
  border: 1px solid #979797;
  display: block;
  padding: 10px 20px;
  border-radius: 10px;
  background: transparent;
  color: #fff;
  font-size: 18px;
  text-align: right;
  width: 130px;
}

.OptionsArea .OptionsBox .OptionsRight .OptionsRisk article aside p input {
  display: block;
  border-radius: 10px;
  background: transparent;
  color: #fff;
  font-size: 18px;
  text-align: center;
  width: 100%;
  border: none;
  outline: 0;
  height: 30px;
}


.calculator-page .page-padding {
  background-image: url('../../assets/price_top.png');
  background-size: 100% 360px;
  background-repeat: no-repeat;
}

.cursor-pointer {
  cursor: pointer;
}